import React from 'react';

const SituacaoHabitacionalOptions = () => {
    return <>
        <option></option>
        <option value={1}>ALUGADO</option>
        <option value={2}>ASSENTAMENTO IRREGULAR</option>
        <option value={3}>CEDIDO</option>
        <option value={4}>COABITAÇÃO</option>
        <option value={5}>OUTROS</option>
        <option value={6}>PARENTES</option>
        <option value={7}>PROPRIO</option>
        <option value={8}>SITUAÇÃO DE RUA</option>
        <option value={9}>ADENSAMENTO EXCESSIVO</option>
    </>;
}

export default SituacaoHabitacionalOptions;