import React from 'react';
import { Button } from '@material-ui/core';
import Modal from 'react-modal';
import ReactCrop from 'react-image-crop';
import {
  base64StringtoFile,
  image64toCanvasRef
} from './ResuableUtils';
import 'react-image-crop/dist/ReactCrop.css';
import './image-drop-box.css';
import './crop-modal.css';

const customStyles = {
  content : {
    top         : '55%',
    left        : '50%',
    right       : 'auto',
    bottom      : 'auto',
    marginRight : '-50%',
    transform   : 'translate(-50%, -50%)'
  }
};

class ImageCropModal extends React.Component {
  constructor(props) {
    super(props)
    this.imagePreviewCanvasRef = React.createRef()
    this.fileInputRef = React.createRef()
    this.cropedFile = null;
    this.state = {
        imgSrc: props.imgSrc,
        imgSrcExt: props.imgSrcExt,
        crop: {},
        cropCallBack: props.cropCallBack,
        show: true,
        cropedImage: null
    }
  }

  handleImageLoaded = (image) => {
    //console.warn(image)
  }

  handleOnCropChange = (crop) => {
      this.setState({crop})
  }

  handleOnCropComplete = (crop, pixelCrop) => {
      const canvasRef = this.imagePreviewCanvasRef.current
      const {imgSrc}  = this.state
      image64toCanvasRef(canvasRef, imgSrc, pixelCrop)
  }

  handleDownloadClick = (event) => {
    event.preventDefault()
    const {crop}  = this.state
    const canvasRef = this.imagePreviewCanvasRef.current

    if (crop.width === 0) {
      crop.width = 100;
    }
    if (crop.height === 0) {
      crop.height = 100;
    }

    const {imgSrcExt} =  this.state
    const imageData64 = canvasRef.toDataURL('image/' + imgSrcExt)

    const myFilename = "previewFile." + imgSrcExt

    // file to be uploaded
    const myNewCroppedFile = base64StringtoFile(imageData64, myFilename)
    this.cropedFile = myNewCroppedFile;
    // download file
    //downloadBase64File(imageData64, myFilename)
    this.handleClearToDefault()
  }

  handleClearToDefault = event => {
      this.handleCallbacks();

      if (event) event.preventDefault()
      const canvas = this.imagePreviewCanvasRef.current
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      this.setState({
          imgSrc: null,
          imgSrcExt: null,
          crop: {}
      })

      if (this.fileInputRef && this.fileInputRef.current) {
          this.fileInputRef.current.value = null
      }

      this.handleClose()
  }

  handleClose = () => {
    this.setState({show: false})
  }

  handleCallbacks = () => {
    const {imgSrc, cropCallBack} = this.state;
    if (cropCallBack) {
      cropCallBack(imgSrc, this.cropedFile);
    }
  }
  
  render() {
    return (
      <div>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.show}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.handleClose}
          style={customStyles}
          contentLabel="Ajuste a imagem"
        >
          <div>
            <ReactCrop
                src={this.state.imgSrc} 
                crop={this.state.crop}
                onImageLoaded={this.handleImageLoaded}
                onComplete = {this.handleOnCropComplete}
                onChange={this.handleOnCropChange}
            />
            <div className="hidden-canvas">
              <canvas ref={this.imagePreviewCanvasRef}></canvas>
            </div>
          </div>
          <div>
            <Button className="crop" type="button" color="inherit" variant="contained" onClick={this.handleDownloadClick}>
                Recortar
            </Button>
            <Button className="cancel" type="submit" color="primary" variant="contained" onClick={this.handleClearToDefault}>
                Cancelar
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ImageCropModal;