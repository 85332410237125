import React from 'react';

const EstadoOptions = () => {
    return <>
        <option></option>
        <option key={1} value={1}>AC</option>
        <option key={2} value={2}>AL</option>
        <option key={3} value={3}>AM</option>
        <option key={4} value={4}>AP</option>
        <option key={5} value={5}>BA</option>
        <option key={6} value={6}>CE</option>
        <option key={7} value={7}>DF</option>
        <option key={8} value={8}>ES</option>
        <option key={9} value={9}>GO</option>
        <option key={10} value={10}>MA</option>
        <option key={11} value={11}>MG</option>
        <option key={12} value={12}>MS</option>
        <option key={13} value={13}>MT</option>
        <option key={14} value={14}>PA</option>
        <option key={15} value={15}>PB</option>
        <option key={16} value={16}>PE</option>
        <option key={17} value={17}>PI</option>
        <option key={18} value={18}>PR</option>
        <option key={19} value={19}>RJ</option>
        <option key={20} value={20}>RN</option>
        <option key={21} value={21}>RR</option>
        <option key={22} value={22}>RS</option>
        <option key={23} value={23}>SC</option>
        <option key={24} value={24}>SE</option>
        <option key={25} value={25}>SP</option>
        <option key={26} value={26}>TO</option>
    </>;
}

export default EstadoOptions;