import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  InputAdornment
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
// import AuthState from '../../../auth/Auth';
import axios from 'axios';
import api from '../../../services/api';
import { ToastMessage } from '../../../layouts';

const useStyles = makeStyles(() => ({
  root: {}
}));

let Photo = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({});
  const [uploading, setUploading] = useState(false);
  const user = props.user;

  const handleFileUpload = event => {
        setValues({
            photo: event.target.files
        })
    };

    const submitFile = (event) => {
        setUploading(true)
        event.preventDefault();
        if (JSON.stringify(values) === "{}") {  
          ToastMessage.error('Caminho do arquivo não pode esta vazio');
          setUploading(false)
          return 0;
        } 
                
        const file = values.photo[0];        

        if (file === '') {
          ToastMessage.error('Caminho do arquivo não pode esta vazio');

        } else {
          // AuthState.getUserInfo().then( (luser) => {       

            api.get(`/users/photo/${user.cpf}`)
              .then((response) => {
                  const preSignedURL = response.data.url
                  axios
                  .put(
                      preSignedURL,
                      file,
                      {
                          headers: {
                              'Content-Type': file.type
                          },
                          reportProgress: true
                      }
                  )
                  .then((response) => {
                      // handle your response
                      api.put(`/users/${user.id}`, {photo: `${user.cpf}/photo.png`})
                      .then((response) => {
                          // AuthState.updateUserPhoto(response.data.url);
                          // setUser(AuthState.getUserInfo())
                          window.location.reload(false);
                          setUploading(false);
                          ToastMessage.success('Perfil atualizado com sucesso');
                      })
                      .catch((error) => {
                          console.error(error);
                          setUploading(false);
                          ToastMessage.error('Problema ao atualizar Perfil');
                      })
                  })
                  .catch((error) => {
                      // handle your error
                      console.error(error);
                      setUploading(false);
                      ToastMessage.error('Problema ao atualizar Perfil');
                  })
              })
              .catch((error) => {
                  console.error(error);
                  setUploading(false);
                  ToastMessage.error('Problema ao atualizar Perfil');
              })
            // })     

        }
             
    }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          subheader="Atualizar foto do Perfil"
          title="Imagem do Perfil"
        />
        <Divider />
        <CardContent>
            <TextField
                fullWidth
                type="file"
                name="photo"
                inputProps={{accept: "image/*"/*, style: {display: "none"}*/}}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                    )
                }}
                onChange={handleFileUpload}
                value={values.file}
                variant="outlined"
            />
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={submitFile}
            disabled={uploading}
          >
            {uploading && 'Uploading...'}
            {!uploading && 'Upload'}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Photo.propTypes = {
  className: PropTypes.string
};

export default Photo;
