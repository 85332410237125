const validEmail = (values) => {
    const errors = {}

    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = 'Endereço de Email invalido'
    }

    return errors;
}

export default validEmail;