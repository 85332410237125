import React from 'react';

const TipoDeVinculoOptions = () => {
    return <>
        <option></option>
        <option key={1} value={1}>OUTROS</option>
        <option key={2} value={2}>PRESTADOR DE SERVIÇO</option>
        <option key={3} value={3}>TRABALHO INDEPENDENTE</option>
        <option key={4} value={4}>TRABALHO EM TEMPO  PARCIAL</option>
        <option key={5} value={5}>TRABALHO PERMANENTE</option>
        <option key={6} value={6}>TRABALHO TEMPORARIO</option>
    </>;
}

export default TipoDeVinculoOptions;