import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Grid, Link } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

import useStyles from './forgotPasswordFormStyle';
import validate from './forgotPasswordFormHelper';

import { renderTextField } from '../Fields/Fields';
import api from '../../services/api';
import { ToastMessage } from '../../layouts';


let ForgotPasswordForm = (props) => {
    const { handleSubmit, submitting, reset } = props;

    const classes = useStyles();

    const sendEmail = ({email}) => {
        return api.post("forgotPassword", {email})
        .then(response => {
            if (response.status === 200) {
                ToastMessage.success("Email enviado");
                reset();
            } else {
                ToastMessage.warning(response.message);
            }
        })
    }


    return (
        <form name="forgotPasswordForm" onSubmit={handleSubmit(sendEmail)}>
                <div className={classes.mainForm}>
                    <br></br>
                    <br></br>
                    <Grid container className={classes.container} wrap="wrap">
                                               
                        <Grid item xs={12} className={classes.mainRigth} >
                            
                            <div className={classes.text2}>                            
                                <strong> Recuperação de senha </strong>
                            </div>
                            <div className={classes.text4}>
                                Informe um e-mail e as instruções para renovação de senha 
                                serão enviadas para para esse endereço
                            </div>

                            <Divider variant="inset" style={{ marginBottom: 50, height: 3 }}></Divider>

                            <center>
                                <Grid item xs={10} >
                                    <Field name="email" type="text" className={classes.emailInput} style={{ width: 250, marginTop:10 }} component={renderTextField} label="Email"/>
                                </Grid>                            

                                <Grid item xs={10}>
                                <Link href="/login" className="">Voltar</Link>
                                </Grid>

                                <br></br>

                                <Button disabled={submitting} type="submit" style={{ borderRadius: 20, width:130 }} color="primary" variant="contained"> Enviar </Button>
                            </center>
                        </Grid>
                    </Grid>
                    
                </div>
            </form>
    );
}

ForgotPasswordForm = reduxForm({
    form: 'ForgotPasswordForm',
    validate
})(ForgotPasswordForm);

export default ForgotPasswordForm;