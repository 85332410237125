import React, {Component} from 'react';

import { css } from "@emotion/react";
import BounceLoader from "react-spinners/BounceLoader";
import 'react-image-crop/dist/ReactCrop.css';
import './image-drop-box.css';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  top: 25px;
`;

class ImageDropAndCropLoading extends Component {
    constructor(props){
        super(props)
    }

    render () {
        return (
            <div>
                <div className="img-drop-box">
                    <BounceLoader color={"#ffffff"} loading={true} css={override} size={60} />
                </div>
            </div>
        )
    }
}

export default ImageDropAndCropLoading;