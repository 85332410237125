import React from 'react';

const EscolaridadeOptions = () => {
    return <>
        <option key={0} value={0}>ALFABETIZADO</option>
        <option key={1} value={1}>ENSINO FUNDAMENTAL COMPLETO</option>
        <option key={2} value={2}>ENSINO FUNDAMENTAL INCOMPLETO</option>
        <option key={3} value={3}>ENSINO MEDIO COMPLETO</option>
        <option key={4} value={4}>ENSINO MEDIO INCOMPLETO</option>
        <option key={5} value={5}>ENSINO SUPERIOR COMPLETO</option>
        <option key={6} value={6}>ENSINO SUPERIOR INCOMPLETO</option>
        <option key={7} value={7}>NÃO ALFABETIZADO</option>
    </>;
}

export default EscolaridadeOptions;