import React, {useState, useEffect} from 'react';
import ImageCard from './ImageCard';
import { Grid, CardContent, CardHeader, Card } from '@material-ui/core';
import AuthState from '../../../../auth/Auth';
import { useHistory } from "react-router-dom";

import FormTabs from '../../FormTabs';
import { useSelector , useDispatch} from 'react-redux';
import FormCandidatoActions from '../../../../store/ducks/FormCandidato';

const formName = 'Documentos';

let Form = (props) => {
    const [authCandidatoId, setAuthCandidatoId] = useState(undefined);
    const dispatch = useDispatch();
    const formCandidatoData = useSelector(state => state.formCandidato.data);            
    const history = useHistory();
    const urlTokens = history.location.pathname.split("/");
    const entity = {id: authCandidatoId || formCandidatoData.id || urlTokens[2]};    
    const [candidatoId, setCandidatoId] = useState(authCandidatoId || formCandidatoData.id || urlTokens[2]);

    const [isAdmin, setIsAdmin] = useState(false);

    AuthState.getUserInfo().then((userInfo) =>{
        setIsAdmin(userInfo.type === 'ADMIN') 
    })

    dispatch(FormCandidatoActions.setFormName({formName:formName}));


    useEffect(() => {        
        setAuthCandidatoId(AuthState.getCandidato());
    }, []);

    return (
        
        <>            
        { (authCandidatoId || formCandidatoData.id || urlTokens[2]) &&  
        <>
            <FormTabs />
            <Grid container wrap="wrap" className="row">
                <Card className="personalData" style={{width: '100%', marginBottom: '5px'}}>
                    <CardHeader title="Cópia de Documentos Necessários"/>
                    <CardContent>
                        <ImageCard 
                            entity={entity}
                            imageUrl={`/candidatos/${candidatoId}/rg`}
                            imageContext={'candidatos-rg'}
                            isSelfEditing={authCandidatoId === formCandidatoData.id || (isAdmin)}
                            name={'RG - Frente e Verso'}
                            maxImages={2}
                        />
                    </CardContent>
                </Card>
            </Grid>

        <Grid container wrap="wrap" className="row">
            <Card className="personalData" style={{width: '100%', marginBottom: '5px'}}>
                <CardContent>
                    <ImageCard 
                        entity={entity}
                        imageUrl={`/candidatos/${candidatoId}/cpf`}
                        imageContext={'candidatos-cpf'}
                        isSelfEditing={(authCandidatoId === formCandidatoData.id) || (isAdmin)}
                        name={'CPF - Frente e Verso'}
                        maxImages={2}
                    />

                </CardContent>
            </Card>
        </Grid>
        <Grid container wrap="wrap" className="row">
            <Card className="personalData" style={{width: '100%', marginBottom: '5px'}}>
                <CardContent>
                    <ImageCard 
                        entity={entity}
                        imageUrl={`/candidatos/${candidatoId}/certCasamento`}
                        imageContext={'candidatos-certCasamento'}
                        isSelfEditing={authCandidatoId === formCandidatoData.id|| (isAdmin)}
                        name={'Certidão de Estado Civil'}
                        maxImages={1}
                    />

                </CardContent>
            </Card>
        </Grid>
        <Grid container wrap="wrap" className="row">
            <Card className="personalData" style={{width: '100%', marginBottom: '5px'}}>
                <CardContent>
                    <ImageCard 
                        entity={entity}
                        imageUrl={`/candidatos/${candidatoId}/compResidencia`}
                        imageContext={'candidatos-compResidencia'}
                        isSelfEditing={authCandidatoId === formCandidatoData.id|| (isAdmin)}
                        name={'Comprovante de Residência'}
                        maxImages={1}
                    />
                </CardContent>
            </Card>
        </Grid>

        <Grid container wrap="wrap" className="row">
            <Card className="personalData" style={{width: '100%', marginBottom: '5px'}}>
                <CardHeader title="Laudo Médico"/>
                <CardContent>
                    <ImageCard 
                        entity={entity}
                        imageUrl={`/candidatos/${candidatoId}/laudo`}
                        imageContext={'candidatos-laudo'}
                        isSelfEditing={authCandidatoId === formCandidatoData.id || (isAdmin)}
                        name={'Caso Exista Alguma Necessidade Médica'}
                        maxImages={1}
                    />
                </CardContent>
            </Card>
        </Grid>

        </>
        }
        </>
    )
}
export default Form;

