import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    mainForm: {
        padding: theme.spacing(4),
        margin: "auto",
        textAlign: "left",
        width: 600
    },
    container: {
        padding: theme.spacing(3)
    },
    mainRight: {
        padding: theme.spacing(2),
        backgroundColor: '#FFF',
        borderRadius: 10,
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
    },
    title: {
        color: '#111111',
        marginRight: theme.spacing(1),
        textShadow: '2px 2px rgba(51, 51, 51, 0.2)',
        letterSpacing: 3,
        fontSize: 20,
        alignContent: "left",
        paddingBottom: 20
    },
    password: {
        marginTop: 10,
    },
    confirmPassword: {
        marginTop: 10,
        marginBottom: 10
    },
    submitButton: {
        marginTop: 10
    },
    backButton: {
        marginTop: 10,
        float: 'right'
    }
}));

export default useStyles;