import React from 'react';
import { Redirect } from 'react-router-dom';
import AuthState from '../../auth/Auth';
import RouteWithLayout from '../RouteWithLayout';

 const ProtectedRoute = (props) => (
    AuthState.getAuthenticated() === true ?
        AuthState.allowedTo(props.path) ?
            <RouteWithLayout {...props} />
            :
        <Redirect to={{ pathname: '/forbidden', state: { from: props.location }}} />
    :
        <Redirect to={{ pathname: '/login', state: { from: props.location }}} />
);

export default ProtectedRoute;