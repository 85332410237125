import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import api from '../../../services/api';
import { Grid } from '@material-ui/core';
import {  renderSelectField } from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';

const formName = 'UserCompanyProfile';
const modelName = 'user/company/profiles';
const label = 'Perfis do Usuário';

const validate = values => {
    const errors = {}
    var requiredFields = [
        'userId',
        'profileId',
        'companyId'
    ]

    // VALIDAÇAO CAMPOS REQUIRE
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Campo obrigatório'
        }
    })

    return errors
}

let Form = (props) => {
    const [userList, setUserList] = useState([]);
    const [loadingUser, setLoadingUser] = useState(false);
    const [profileList, setProfileList] = useState([]);
    const [loadingProfile, setLoadingProfile] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [loadingCompany, setLoadingCompany] = useState(false);

    const getUserList = () => {
        if (!loadingUser) {
            setLoadingUser(true);
            api.get('users?pageSize=-1').then(list => {
                setUserList(list.data.rows);
            });
        }
    }

    const getProfileList = () => {
        if (!loadingProfile) {
            setLoadingProfile(true);
            api.get('profiles?pageSize=-1').then(list => {
                setProfileList(list.data.rows);
            });
        }
    }

    const getCompanyList = () => {
        if (!loadingCompany) {
            setLoadingCompany(true);
            api.get('companies?pageSize=-1').then(list => {
                setCompanyList(list.data.rows);
            });
        }
    }

    const renderSelection = (list) => {
        if (list && list.length > 0) {
            let finalList = [<option key={'none'}></option>];
            list.forEach((entity) => {
                finalList.push(<option key={entity.id} value={entity.id}>{entity.name}</option>);
            })
            return (finalList);
        }
    }

    getUserList();
    getProfileList();
    getCompanyList();

    return (
        <BasicForm name={formName}
            content={
                <Grid container spacing={3} wrap="wrap">
                    <Grid item xs={12}>
                        <Field name="userId" component={renderSelectField} label="Usuário" style={{ width: 450 }}>
                            {renderSelection(userList)}
                        </Field>
                    </Grid>

                    <Grid item xs={12}>
                        <Field name="profileId" component={renderSelectField} label="Perfil" style={{ width: 450 }}>
                            {renderSelection(profileList)}
                        </Field>
                    </Grid>

                    <Grid item xs={12}>
                        <Field name="companyId" component={renderSelectField} label="Empresa" style={{ width: 450 }}>
                            {renderSelection(companyList)}
                        </Field>
                    </Grid>
                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues:{'status':'A'}
})(Form);

export default Form;

