
import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

const {Types, Creators} = createActions({    
    setComentario: ['value'],

});

export const GridListTypes = Types;

const INITIAL_STATE = Immutable({    
    comentario: '',
});

export const reducer = createReducer(INITIAL_STATE,{    
    [Types.SET_COMENTARIO]: (state, action) => {return state.merge({comentario:action.value})},
});

export default Creators;
