import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Grid, Divider, CardContent, CardHeader, Card } from '@material-ui/core';
import { renderTextField, renderSelectField, renderTextAreaField } from '../../../../components/Fields/Fields';
import BasicForm from '../../../../components/BasicForm';
import TipoDeficienciaOptions from '../../components/TipoDeficienciaOptions';
import { numberMaskNoDescimal } from '../../../../services/masks';

import FormTabs from '../../FormTabs';
import { useSelector , useDispatch} from 'react-redux';
import FormCandidatoActions from '../../../../store/ducks/FormCandidato';
import '../../required-field.css';

const formName = 'Família';
const modelName = 'familias';
const label = 'Família';

const isRequired = () => {
    return {required: true}
}

const validate = (values) => {
    const errors = {}

    const requiredFields = [
        'laudo',
        'idoso'
    ]

    if (values.deficiencia === 'SIM') {
        requiredFields.push('tiponecessidadeespecial')
    }

    if (values.laudo === 'SIM') {
        requiredFields.push('informacaolaudo')
    }

    if (values.idoso === 'SIM') {
        requiredFields.push('idadeidoso')
    }

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Campo Origatório'
        }
    })

    if (values['temporesidejp'] && values['temporesidejp'] > 99) {
        errors.temporesidejp = 'Tempo que Reside em João Pessoa (anos) inválido: intervalo (0 a 99)';
    }

    if (values['temposituacaohabitacional'] && values['temposituacaohabitacional'] > 99) {
        errors.temposituacaohabitacional = 'Tempo que encontra-se em habitação (anos) inválido: intervalo (0 a 99)';
    }

    return errors;
}

let Form = (props) => {

    const dispatch = useDispatch();
    const formCandidatoData = useSelector(state => state.formCandidato.data);

    const upper = value => value && value.toUpperCase()

    dispatch(FormCandidatoActions.setFormName({formName:formName}));

    const beforeSubmit = (values) => {
        values.candidatoId = formCandidatoData.id;
    }

    return (
        <>
            <FormTabs />
            <BasicForm
                routeAfterSubmit={`/candidatos/${formCandidatoData.id}`}
                customBeforeSubmit={beforeSubmit}
                hiddenNewButton={true}  
                content={
                    <Grid container wrap="wrap" className="row">
                        <Card className="personalData">
                            <CardHeader title="Estrutura Familiar"/>
                            <CardContent>
                                <Grid container spacing={2} wrap="wrap">

                                    <Grid item xs={6}>
                                        <Field label="Portador de necessidades especiais na composição familiar" name="deficiencia" component={renderSelectField} style={{maxWidth: '100%',  width: 350 }}>
                                            <option value={'NAO'}>NÃO</option>
                                            <option value={'SIM'}>SIM</option>                                            
                                        </Field>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Field label="Tipo de Necessidade" name="tiponecessidadeespecial" component={renderSelectField} style={{maxWidth: '100%',  width: 350 }}>
                                            <TipoDeficienciaOptions />
                                        </Field>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Field label="Necessidade de Cadeira de rodas" name="cadeiraroda" component={renderSelectField} style={{maxWidth: '100%',  width: 350 }}>
                                            <option value={'NAO'}>NÃO</option>
                                            <option  value={'SIM'}>SIM</option>                                            
                                        </Field>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Field label="Laudo médico apresentado" name="laudo" component={renderSelectField} style={{maxWidth: '100%',  width: 350 }} {...isRequired()}>
                                            <option value={'NAO'}>NÃO</option>
                                            <option value={'SIM'}>SIM</option>                                            
                                        </Field>
                                    </Grid>    

                                    <Grid item xs={6}>
                                        <Field name="informacaolaudo" type="text" component={renderTextAreaField} label="Informações sobre o Laudo"  style={{maxWidth: '100%',  width: 350 }} normalize={upper} {...isRequired()}/>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Grid item xs={12}>
                                            <Field name="qtddependente" type="text" component={renderTextField} label="Quantidade de dependentes" style={{maxWidth: '100%',  width: 350, paddingBottom: 20 }} normalize={upper} {...numberMaskNoDescimal}/>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field label="Cor/Etinia" name="coretinia" component={renderSelectField} style={{maxWidth: '100%', width: 350 }}>
                                                <option></option>
                                                <option value={1}>BRANCO</option>
                                                <option value={2}>NEGRO</option>
                                                <option value={3}>PARDO</option>
                                                <option value={4}>AMARELO</option>
                                                <option value={5}>INDIGENA</option>
                                            </Field>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item xs={6}>
                                        <Field label="Algum idoso na composição familiar" name="idoso" component={renderSelectField} style={{maxWidth: '100%', width: 350 }}>
                                            <option value={'NAO'}>NÃO</option>    
                                            <option value={'SIM'}>SIM</option>                                            
                                        </Field>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Field name="idadeidoso" type="text" component={renderTextField} label="Idade atual do idoso" style={{maxWidth: '100%', width: 350 }} normalize={upper} {...numberMaskNoDescimal} {...isRequired()}/>
                                    </Grid>                                                                                                   

                                </Grid>
                                
                            </CardContent>
                        </Card>

                        <Divider />
                    </Grid>
                }
                formName={formName}
                modelName={modelName}
                label={label}
                { ...props }
            />
        </>
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues:{
        'tiponecessidadeespecial':4,
     
    }
})(Form);

export default Form;

