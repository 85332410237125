
import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

const {Types, Creators} = createActions({    
    setLoadImages: ['value'],

});

export const GridListTypes = Types;

const INITIAL_STATE = Immutable({    
    loadImages: false,
});

export const reducer = createReducer(INITIAL_STATE,{    
    [Types.SET_LOAD_IMAGES]: (state, action) => {return state.merge({loadImages:action.value})},
});

export default Creators;
