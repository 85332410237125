import React, {useState, useEffect} from 'react';

import { Field, reduxForm, change } from 'redux-form';
import { useParams } from "react-router-dom";
import { renderTextField, renderSelectField, renderDatePicker } from '../../../../components/Fields/Fields';
import BasicForm from '../../../../components/BasicForm';
import { cpfMask, moneyMask } from '../../../../services/masks';
import validCpf from '../../../../services/validCpf';

// import { validate } from '../../../../services';
import { Grid, Divider, CardContent, CardHeader, Card } from '@material-ui/core';

import api from '../../../../services/api';
import EstadoOptions from '../../components/EstadoOptions';
import EscolaridadeOptions from '../../components/EscolaridadeOptions';
import EstadoCivilOptions from '../../components/EstadoCivilOptions';
import TipoDeVinculoOptions from '../../components/TipoDeVinculoOptions';
import LocalTrabalhoOptions from '../../components/LocalTrabalhoOptions';
import FormTabs from '../../FormTabs';
import { useSelector , useDispatch} from 'react-redux';
import FormCandidatoActions from '../../../../store/ducks/FormCandidato';
import '../../required-field.css';

const formName = 'Conjuge';
const modelName = 'conjuges';
const label = 'Cônjuge';

const isRequired = () => {
    return {required: true}
}

const validate = values => {
    const errors = {}
    var requiredFields = [
        'cpf',
        'nome',
        'rg',
        'rgestado',
        'rgorgao',
        'datanascimento',
        'estadocivil',
        'ocupacaoId',
        'tipovinculo',
        'rendabruta'
    ]


    // VALIDAÇAO CAMPOS REQUIRE
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Campo obrigatório'
        }
    })

    // VALIDAÇAO CPF
    if (values.cpf && !validCpf(values.cpf)) {
        errors['cpf'] = 'Cpf inválido';
    }

    return errors
}

let Form = (props) => {
    const [ocupacaoList, setOcupacaoList] = useState([]);    

    const dispatch = useDispatch();
    const formCandidatoData = useSelector(state => state.formCandidato.data);

    const { candidatoId } = useParams();

    const upper = value => value && value.toUpperCase()

    dispatch(FormCandidatoActions.setFormName({formName:formName}));

    const getOcupacaoList = () =>  {
        api.get('ocupacao?pageSize=-1').then(response => {
            let list = [];
            response.data.rows.forEach((entity) => {
                list.push({name: entity.nome, value: entity.id})
            });
            setOcupacaoList(list);
        });
    }

    const renderOcupacaoSelection = () => {
        if (ocupacaoList && ocupacaoList.length > 0) {
            let list = [];
            ocupacaoList.forEach((ocupacao) => {
                list.push(<option key={ocupacao.value} value={ocupacao.value}>{ocupacao.name}</option>);
            })

            return (list);
        }
    }

    const beforeSubmit = (values) => {
        values.candidatoId = candidatoId;

        values.cadastropessoal = {
            cpf: values.cpf,
            nome: values.nome,
            escolaridade: values.escolaridade,
            datanascimento: values.datanascimento,
            genero: values.genero,
            rg: values.rg,
            rgorgao: values.rgorgao,
            rgestado: values.rgestado,
            estadocivil: values.estadocivil,
            documentoestadocivil: values.documentoestadocivil,
            ocupacaoId: values.ocupacaoId,
            tipovinculo: values.tipovinculo,
            localtrabalho: values.localtrabalho,
            rendabruta: values.rendabruta,
            beneficio: values.beneficio,
            valorbeneficio: values.valorbeneficio
        }
    }

    const afterLoadEntity = (entity) => {
        Object.keys(entity.cadastropessoal).forEach(key => {
            props.dispatch(change(formName, key, entity.cadastropessoal[key]));

            if (entity.cadastropessoal.documentoestadocivil) {
                props.dispatch(change(formName, 'documentoestadocivil', entity.cadastropessoal.documentoestadocivil.documentoestadocivil));
            }
        })
        
        if (entity.id) {
            props.dispatch(change(formName, 'submit_type', 'update'));
        }
    }

    useEffect(() => getOcupacaoList(), []);

    return (
        <>
            <FormTabs />
            <BasicForm
                routeAfterSubmit={`/candidatos/${formCandidatoData.id}/conjuge/:id`}
                customBeforeSubmit={beforeSubmit}
                customAfterLoadEntity={afterLoadEntity}
                content={
                    <Grid container wrap="wrap" className="row">
                        <Card className="personalData">
                            <CardHeader title="Dados do Conjugue"/>
                            <CardContent>
                                <Grid container spacing={3} wrap="wrap">
                                    <Grid item xs={12} >
                                        <Field name="cpf" type="text" component={renderTextField} label="CPF" normalize={upper} style={{maxWidth: '100%', width: 300 }} maxLength={11} {...cpfMask()} {...isRequired()} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field name="nome" type="text" component={renderTextField} label="Nome" normalize={upper} style={{ maxWidth: '100%', width: '95%' }} {...isRequired()} />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Field label="Escolaridade" name="escolaridade" component={renderSelectField} style={{maxWidth: '100%', width: '350px'}}>
                                            <EscolaridadeOptions />
                                        </Field>
                                    </Grid>      

                                    <Grid item xs={3}>
                                        <Field name="datanascimento" type="date" label="Data de nascimento" component={renderDatePicker} style={{maxWidth: '100%', width: 350}} {...isRequired()}/>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Field label="Gênero" name="genero" component={renderSelectField} style={{maxWidth: '100%', width: 150}}>
                                            <option value={'Masculino'}>Masculino</option>
                                            <option value={'Feminino'}>Feminino</option>
                                        </Field>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Field name="rg" type="text" component={renderTextField} label="RG" normalize={upper} maxLength={11} style={{maxWidth: '100%', width: '350px'}} {...isRequired()}/>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Field name="rgorgao" type="text" component={renderTextField} label="Órgão Emissor" normalize={upper} style={{maxWidth: '100%', width: '350px'}} maxLength={11} {...isRequired()}/>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Field label="Estado" name="rgestado" component={renderSelectField} style={{maxWidth: '100%', width: 150}} {...isRequired()}>
                                            <EstadoOptions />
                                        </Field>
                                    </Grid>


                                    <Grid item xs={6}>
                                        <Field label="Estado Civil" name="estadocivil" component={renderSelectField} style={{maxWidth: '100%', width: '350px'}} {...isRequired()}>
                                            <EstadoCivilOptions />
                                        </Field>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Field name="documentoestadocivil" type="text" component={renderTextField}  style={{maxWidth: '100%', width: '350px'}} label="Docum. de estado Civil" normalize={upper} maxLength={11}/>
                                    </Grid>

                                </Grid>
                            </CardContent>
                        </Card>

                        <Divider />
                        
                        <Card className='cardPersonalData'>
                            <CardHeader title="Dados Profissionais"/>
                            <CardContent>
                                <Grid container spacing={3} wrap="wrap">

                                    <Grid item xs={6}>
                                        <Field id="ocupacaoId" name="ocupacaoId" component={renderSelectField} label="Ocupacao" style={{ maxWidth: '100%',width: 350}} {...isRequired()}>
                                            {renderOcupacaoSelection()}
                                        </Field>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Field label="Tipo de Vínculo" name="tipovinculo" component={renderSelectField} style={{ maxWidth: '100%',width: 350}} {...isRequired()}>
                                            <TipoDeVinculoOptions />
                                        </Field>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Field label="Localização do Bairro do Trabalho" name="localtrabalho" component={renderSelectField} style={{ maxWidth: '100%', width: 350 }}>
                                            <LocalTrabalhoOptions />
                                        </Field>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Field name="rendabruta" type="text" component={renderTextField} style={{maxWidth: '100%', width: '350px'}} label="Renda Bruta" {...moneyMask} {...isRequired()}/>
                                    </Grid>


                                    <Grid item xs={6}>
                                        <Field name="beneficio" type="text" component={renderTextField} label="Benefício Governamental" style={{ maxWidth: '100%', width: 350 }}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field name="valorbeneficio" type="text" component={renderTextField}  style={{maxWidth: '100%', width: '350px'}} label="Valor mensal" {...moneyMask}/>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                }
                formName={formName}
                modelName={modelName}
                label={label}
                { ...props }
            />
        </>
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues:{'status':'A'}
})(Form);

export default Form;

