import React, { useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import AuthState from '../../../../auth/Auth';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 2
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  signInButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Iconbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;
  const history = useHistory();

  const classes = useStyles();

  const [notifications] = useState([]);

  const signOut = () => {
    AuthState.logout(() => {
      history.push("/login")
    });
  }

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      style = {{marginTop: 95, marginLeft:50, height:30}}
    >
      <Toolbar style = {{ marginLeft:'2%'}}>
        <RouterLink to="/" >
          <img
            alt="Logo"
            src="/images/logos/logo-pmjp-color-horizontal.png"
            width="25%" 
            height="25%"            
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              
            </Badge>
          </IconButton>
          {AuthState.getAuthenticated() === true && <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={signOut}
          >
            <InputIcon />
          </IconButton>}
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Iconbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Iconbar;
