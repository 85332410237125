import React from 'react';
import { Field, reduxForm} from 'redux-form';
import { validate } from '../../../services';
import { Grid } from '@material-ui/core';
import { renderTextField, renderTextAreaField } from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';

const formName = 'Assunto';
const modelName = 'assuntos';
const label = 'Assuntos';

let Form = (props) => {
    // const [entity, setEntity] = useState({});

    const upper = value => value && value.toUpperCase()
    
    // const afterLoadEntity = (entity) => {
    //     setEntity(entity);
    // }

    // const afterInitialState = () => {
    //     setEntity({});
    // }

    return (
        <BasicForm
            // customAfterLoadEntity={afterLoadEntity}
            keepUpdateAfterSubmit={true}
            // customAfterInitialState={afterInitialState}
            content={
                <Grid container spacing={3} wrap="wrap">

                    <Grid item xs={12} >
                        <Field name="titulo" type="text" component={renderTextField} label="Titulo" normalize={upper} style={{ width: 300 }} />
                    </Grid>

                    <Grid item xs={6}>
                        <Field name="descricao" type="textarea" component={renderTextAreaField} label="Descrição" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>                 

                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues:{}
})(Form);

export default Form;

