import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import reducers from './ducks';
import sagas from './sagas';

export const AuthStore = {
  isAuthenticated: false,
  grants: [],
  userInfo: {}
}

if (!sessionStorage.getItem('auth')) {
  sessionStorage.setItem('auth', JSON.stringify(AuthStore));
}

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];



const composer = process.env.NODE_ENV === 'development'
  ? compose(
    applyMiddleware(...middlewares),
  )
  : applyMiddleware(...middlewares);

const store = createStore(reducers, composer);

sagaMiddleware.run(sagas);

export default store;
