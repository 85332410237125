import React, {Component} from 'react';

import IconButton from '@material-ui/core/IconButton';
import 'react-image-crop/dist/ReactCrop.css';
import './image-drop-box.css';
import AddIcon from '@material-ui/icons/Add';
import ImageCropModal from './ImageCropModal';

import {extractImageFileExtensionFromBase64} from './ResuableUtils';

const imageMaxSize = 5000000; // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif';
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() });

class ImageDropAndCrop extends Component {
    constructor(props){
        super(props)
        this.name = Math.floor((Math.random() * 100) + 1);
        this.imagePreviewCanvasRef = React.createRef()
        this.fileInputRef = React.createRef()
        this.state = {
            imgSrc: null,
            imgSrcExt: null,
            fileSelected: false,
            crop: {}
        }
    }

    verifyFile = (files) => {
        if (files && files.length > 0){
            const currentFile = files[0]
            const currentFileType = currentFile.type
            const currentFileSize = currentFile.size
            if(currentFileSize > imageMaxSize) {
                alert("This file is not allowed. " + currentFileSize + " bytes is too large")
                return false
            }
            if (!acceptedFileTypesArray.includes(currentFileType)){
                alert("This file is not allowed. Only images are allowed.")
                return false
            }
            return true
        }
    }

    handleFileSelect = event => {
        this.setState({
            fileSelected: true
        })
        const files = event.target.files
        if (files && files.length > 0){
              const isVerified = this.verifyFile(files)
             if (isVerified){
                 // imageBase64Data 
                 const currentFile = files[0]
                 const myFileItemReader = new FileReader()
                 myFileItemReader.addEventListener("load", (evt)=>{
                     // console.log(myFileItemReader.result)
                     const myResult = myFileItemReader.result
                     this.setState({
                         imgSrc: myResult,
                         imgSrcExt: extractImageFileExtensionFromBase64(myResult)
                     })
                 }, false)

                 myFileItemReader.readAsDataURL(currentFile)

             }
        }
    }

    selectFile = () => {
        const input = document.getElementsByName("hiddenInputFile_" + this.name);
        if (input && input[0]) {
            input[0].click();
        }
    }

    cropCallBack = (base64Image, fileImage) => {
        const {callback} = this.props;

        if (callback) {
            callback(base64Image, fileImage);
        }
        this.setState({imgSrc: null});
    }

    render () {
        const {imgSrc, imgSrcExt} = this.state
        return (
            <div>
                {imgSrc !== null ? 
                    <div>
                        <ImageCropModal imgSrc={imgSrc} imgSrcExt={imgSrcExt} cropCallBack={this.cropCallBack}/>
                    </div>

                : 
                    <div className="img-drop-box">
                        <IconButton aria-label="add" className="add-button" onClick={this.selectFile}>
                            <AddIcon fontSize="large" />
                        </IconButton>
                        <input className="hiddenInputFile" name={"hiddenInputFile_" + this.name} ref={this.fileInputRef} type='file' accept={acceptedFileTypes} multiple={false} onChange={this.handleFileSelect} />
                    </div>
                }
            
            </div>
        )
    }
}

export default ImageDropAndCrop;