import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import Form from './components/Form';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  }
}));

const FormConcorrencia = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          md={8}
          xs={12}
        >
          <Form/>
        </Grid>
      </Grid>
    </div>
  );
};

export default FormConcorrencia;
