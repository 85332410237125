import React, {useState} from 'react';
import { Button } from '@material-ui/core';
import api from '../../../../services/api';
import { confirmAlert } from 'react-confirm-alert';
import { ToastMessage } from '../../../../layouts';

const INSCRIPTION_CONFIRM = 'INSCRIPTION_CONFIRM';
const HAS_COMPROVANTE = 'HAS_COMPROVANTE';
const NO_EDITAL = 'NO_EDITAL';

const StateButton = props => {
    const [submitting, setSubmitting] = useState(false)

    const handleClickComprovante = () => {
        const {candidatoId, concorrencia} = props;
        console.error(concorrencia)
        setSubmitting(true);
        api.get(`/candidatos/inscricoes/${candidatoId}/pdf`, 
            { 
                params: { concorrenciaId: concorrencia.id },
                responseType: 'blob'
            }
        )
        .then(response => {
            const file = new Blob(
                [response.data], 
                {type: 'application/pdf'}
            );
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL, "_blank")
            setSubmitting(false)
        })
        .catch(err => setSubmitting(false))
    }

    const handleClickInscrition = () => {
        const {concorrencia, candidatoId, onSuccess} = props;

        confirmAlert({
            title: 'Confirma de Inscrição',
            // message: `Confirma exclusão do ${modelName}: ${row.name ? row.name : row.id}?`,
            message: `Confirma a Inscrição na Concorrencia ${concorrencia.name} numero: ${concorrencia.number} referente a: ${concorrencia.edital_text}?`,
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        setSubmitting(true);
                        api.post('/inscricoes', {
                            candidatoId: candidatoId,
                            concorrenciaId: concorrencia.id,
                            status: 'A'
                        })
                            .then(response => {
                                setSubmitting(false);
                                if (onSuccess) {
                                    onSuccess(response.data);
                                }
                                ToastMessage.success('Inscrição realizada com Sucesso');
                            })
                            .catch(error => {
                                // deleteCallbackFailure(error);
                                setSubmitting(false);
                            });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => {
                        
                    }
                }
            ]
        });
    }

    const getState = () => {
        const { canDoInscription, inscription, concorrencia } = props;

        if (!concorrencia) return NO_EDITAL;

        if (!canDoInscription || !inscription) return INSCRIPTION_CONFIRM;

        return HAS_COMPROVANTE;
    }

    const getButtonText = () => {
        if (getState() === INSCRIPTION_CONFIRM) {
            return 'Confirmar Inscrição';
        } else if (getState() === NO_EDITAL) { 
            return 'Nenhuma Edital disponível';
        }

        return 'Comprovante';
    }

    const getIsButtonDisabled = () => {
        const { canDoInscription, concorrencia } = props;
        return !canDoInscription || submitting || !concorrencia;
    }

    return (
        <Button
            color="primary"
            variant="outlined"
            disabled={getIsButtonDisabled()}
            onClick={getState() === INSCRIPTION_CONFIRM ? handleClickInscrition : handleClickComprovante}
        >
            {getButtonText()}
        </Button>
    )
}

export default StateButton;