import React, {useState} from 'react';
import { change, Field, reduxForm } from 'redux-form';
import { validate } from '../../../services';
import { Grid, CardHeader } from '@material-ui/core';
import { renderTextField, renderTextAreaField, renderSelectField } from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';
import { numberMaskNoDescimal } from '../../../services/masks';
import apiCEP from '../../../services/apiCEP';
import { ToastMessage } from '../../../layouts';
import api from '../../../services/api';
import AuthState from '../../../auth/Auth';

const formName = 'Demanda';
const modelName = 'demandas';
const label = 'Demandas Simples';

const isRequired = () => {
    return {required: true}
}

let Form = (props) => {
        
    const [isComunidade, setIsComunidade] = useState(false);    
    const [assuntoList, setAssuntoList] = useState([]);    
    const [slaList, setSlaList] = useState([]);  
    const [secretariaList, setSecretariaList] = useState([]);  

    const [loadingAssunto, setLoadingAssunto] = useState(false);
    const [loadingSla, setLoadingSla] = useState(false);
    const [loadingSecretaria, setLoadingSecretaria] = useState(false);
    // const isAdmin = (AuthState.getProfileInfo() && AuthState.getProfileInfo().isAdmin) || false;

    const [entity, setEntity] = useState({});

    const upper = value => value && value.toUpperCase()
    
    const afterLoadEntity = (entity) => {
        setEntity(entity);
        if (entity.irregulacomunidade === "SIM"){
            setIsComunidade(true)
        } else {
            setIsComunidade(false)        
        }
    }

    const afterInitialState = () => {
        setEntity({});
    }

    const getUserId = () =>{      
        AuthState.getUserInfo().then( (user) => {
            props.dispatch(change(formName, 'ownerId', user.id));
        }
        )
    }

    const getSecretariasList = () => {

        if (!loadingSecretaria) {
            setLoadingSecretaria(true);
            api.get('/secretarias?pageSize=-1').then(list => {
                setSecretariaList(list.data.rows);                
            });
        }
    }
    
    const getAssuntoList = () => {

        if (!loadingAssunto) {
            setLoadingAssunto(true);
            api.get('assuntos?pageSize=-1').then(list => {
                setAssuntoList(list.data.rows);                
            });
        }
    }

    const getSlaList = () => {

        if (!loadingSla) {
            setLoadingSla(true);
            api.get('slas?pageSize=-1').then(list => {
                setSlaList(list.data.rows);                
            });
        }
    }    

    const setIsComunidadeView = (event) =>{
        if (event.target.value === 'SIM') {
            setIsComunidade(true)
        } else {
            setIsComunidade(false)
        }
    }

    const getDataByCEP = (event) => {
        const numberPattern = /\d+/g;
        let cep = (event.target.value.match( numberPattern ) || []).join("");

        if (cep) {
            apiCEP.get(`/ws/${cep}/json`)
            .then(data => {
                const values = data.data;
                props.dispatch(change(formName, 'rua', values.logradouro.toUpperCase()));
                props.dispatch(change(formName, 'bairro', values.bairro.toUpperCase()));
                props.dispatch(change(formName, 'complemento', values.complemento.toUpperCase()));
            })
            .catch(error => {
                console.error(error);
                ToastMessage.error('CEP não encontrado');
            })
        }
    }

    const renderSelection = (list) => {
        if (list && list.length > 0) {
            let finalList = [<option key={'none'}></option>];
            list.forEach((entity) => {
                finalList.push(<option key={entity.id} value={entity.id}>{entity.titulo||entity.nome}</option>);
            })
            return (finalList);
        }
    }

    getAssuntoList();
    getSlaList();
    getSecretariasList();
    getUserId();

    return (
        <BasicForm
            imageContext={'demandas'}
            customAfterLoadEntity={afterLoadEntity}
            keepUpdateAfterSubmit={true}
            routeAfterSubmit={'/demandasSimples/:id'}
            // routeAfterSubmit={isAdmin?'/demandasSimples/':`/home`} 
            customAfterInitialState={afterInitialState}  
            hiddenNewButton={true}          
            content={
                <Grid container spacing={3} wrap="wrap">

                    <Grid item xs={12}>
                        <CardHeader title="Localização"/>
                    </Grid>

                    <Grid item xs={6} >
                        <Field name="protocolo" type="text" disabled={true} component={renderTextField} label="Protocolo" normalize={upper} style={{maxWidth: '100%', width: 350 }} />
                    </Grid> 

                    <Grid item xs={6}>
                        <Field name="cep" onBlur={getDataByCEP} type="text" component={renderTextField} label="CEP" style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>

                    <Grid item xs={6}>
                        <Field label="Comunidade Irregular" onBlur={setIsComunidadeView} name="irregulacomunidade" component={renderSelectField}  style={{maxWidth: '100%', width: 350}} {...{required: true}} >
                            <option value={'SIM'}>Sim</option>
                            <option value={'NAO'}>Não</option>                            
                        </Field>
                    </Grid>  

                    <Grid item xs={6} >
                        <Field name="nomeirregulacomunidade" type="text" disabled={!isComunidade} component={renderTextField} label="Nome da Comunidade" normalize={upper} style={{maxWidth: '100%', width: 350 }} />
                    </Grid>      

                    <Grid item xs={6} >
                        <Field name="rua"  type="text" component={renderTextField} label="Rua" normalize={upper} style={{maxWidth: '100%', width: 350 }} />
                    </Grid> 

                    <Grid item xs={2}>
                        <Field name="numero" type="text" component={renderTextField} label="Número" style={{ maxWidth: '100%', width: 100 }}  normalize={upper} {...numberMaskNoDescimal} {...isRequired()}/>
                    </Grid>                    

                    <Grid item xs={4}>
                        <Field name="complemento" type="text" component={renderTextField} label="Complemento" style={{ maxWidth: '100%', width: 200 }}  normalize={upper} />
                    </Grid>    

                    <Grid item xs={12} >
                        <Field name="bairro" type="text" component={renderTextField} label="Bairro" normalize={upper} style={{maxWidth: '100%', width: 350 }} />
                    </Grid>   

                    <Grid item xs={12} >
                        <CardHeader title="Informações da Demanda"/>                    
                    </Grid>   

                    <Grid item xs={6}>                        
                        <Field name="assuntoId" component={renderSelectField} label="Assunto" style={{ maxWidth: '100%', width: 350 }}>
                            {renderSelection(assuntoList)}
                        </Field>                        
                    </Grid> 

                    <Grid item xs={6}>
                        {entity && entity.secretariaId &&
                        <Field disabled={true} name="secretariaId" component={renderSelectField} label="Secretaria" style={{ maxWidth: '100%', width: 350 }}>
                            {renderSelection(secretariaList)}
                        </Field>
                        }
                    </Grid>                        

                    <Grid item xs={12}>
                        {entity && entity.secretariaId &&
                        <Field disabled={true} name="slaId" component={renderSelectField} label="SLA" style={{ maxWidth: '100%', width: 350 }}>
                            {renderSelection(slaList)}
                        </Field>
                        }
                    </Grid>                     

                    <Grid item xs={12} >
                        <Field name="descricao" type="textarea" component={renderTextAreaField} label="Descrição" normalize={upper} style={{maxWidth: "100%", width: 800 }} />
                    </Grid>  

                    <Grid item xs={4}>
                        {entity && entity.secretariaId &&
                        <Field disabled={true} label="Situação" name="situacao" component={renderSelectField}  style={{maxWidth: '100%', width: 200}} {...{required: true}} >
                            <option value={'PENDENTE'}>Pendente</option>
                            <option value={'ANALISE'}>Análise</option>
                            <option value={'ENCAMINHADA'}>Encaminhada</option>
                            <option value={'RESOLVIDA'}>Resolvida</option>
                        </Field>
                        }
                    </Grid>     

                    <Grid item xs={4}>
                        {entity && entity.secretariaId &&
                        <Field disabled={true}  label="Qualidade" name="qualidade" component={renderSelectField}  style={{maxWidth: '100%', width: 200}} {...{required: true}} >
                            <option value={'REPRIMIDA'}>Reprimida</option>
                            <option value={'PRIORITARIA'}>Prioritária</option>
                            <option value={'NORMAL'}>Normal</option>
                        </Field>
                        }
                    </Grid>  

                    <Grid item xs={4}>
                        {entity && entity.secretariaId &&
                        <Field disabled={true} label="Tipo" name="tipo" component={renderSelectField}  style={{maxWidth: '100%', width: 200}} {...{required: true}} >
                            <option value={'ZELADORIA'}>Zeladoria</option>
                            <option value={'ESTRUTURANTE'}>Estruturante</option>
                        </Field>
                        }
                    </Grid>                                                                                                                    

                    <Grid item xs={12}>
                        <Field name="ownerId" id="ownerId" type="hidden" component={renderTextField} />
                    </Grid> 


                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues:{
        'tipo':'ZELADORIA', 
        'qualidade': 'NORMAL', 
        'situacao':'PENDENTE', 
        'irregulacomunidade':'NAO',
    }
})(Form);

export default Form;

