import React from 'react';

const TipoDeficienciaOptions = () => {
    return <>        
        <option value={5}>NENHUMA</option>
        <option value={1}>AUDITIVA</option>
        <option value={2}>MENTAL </option>
        <option value={3}>MOTORA</option>
        <option value={4}>VISUAL</option>        
        <option value={6}>OUTROS</option>
    </>;
}

export default TipoDeficienciaOptions;