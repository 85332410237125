import { ToastMessage } from "../layouts";
import api from "./api";

export const remoteDeleteImage = (entity, image, imageContext) => {
    return api.get(`/upload/delete?context=${encodeURIComponent(image.context)}`)
    .then((response) => {
        return api.doDeleteImage(
            imageContext,
            entity,
            {
                id: image.id,
                context: image.context,
                url: response.data.url,
                file: undefined,
            }
        ).then((response) => {
            const deletedImage = {
                id: image.id,
                context: image.context,
                url: undefined,
                file: undefined,
            };

            return deletedImage;
        })
        .catch((error) => {
            ToastMessage.error('Falha no upload do arquivo');
        })
    })
}

export const uploadFile = (entity, images, base64Image, fileImage, imageContext) => {
    let urlImageContext = imageContext;
    if (imageContext && imageContext.indexOf('/') > 0) {
        urlImageContext = imageContext.replaceAll('/', '%2F');
    }
    return api.get(`/upload/get/${urlImageContext}`)
    .then((response) => {
        if (entity) {
            const context = response.data.context;
            
            return api.doUploadImage(
                imageContext,
                entity,
                {
                    id: entity.id,
                    context: response.data.context,
                    url: response.data.url,
                    file: fileImage,
                }
            ).then((response) => {
                if (!response) throw Error('Problemas no upload de imagens')
                let newImages = [];

                images.forEach((image) => {
                    newImages.push(
                        {
                            id: image.id,
                            context: image.context,
                            url: image.url,
                            file: image.file
                        }
                    )
                })

                newImages.push({
                    id: response.data.id,
                    context: context,
                    url: response.data.url,
                    file: undefined
                })
                
                return newImages;
            })
            .catch(error => {
                ToastMessage.error('Falha no upload do arquivo');
            })
        } else {
            return [{
                url: response.data.url,
                file: fileImage,
                context: response.data.context,
                id: undefined
            }]
        }
    });
}
