import React from 'react';
import ImageDropAndCrop from '../ImageDropAndCrop/ImageDropAndCrop';
import ImageDropAndCropLoading from '../ImageDropAndCrop/ImageDropAndCropLoading';
import ImageCropView from '../ImageDropAndCrop/ImageCropView';
import './carousel.css';
import { useDispatch } from 'react-redux';
import CarouselActions from '../../store/ducks/Carousel';

const Carousel = (props) => {

    const dispatch = useDispatch();

    const deleteCallback = props.deleteCallback;
    const images = props.images;
    const addCallback = props.addCallback;
    const entity = props.entity;
    const maxImages = props.maxImages;
    const loading = props.loading;
    const imageContext = props.imageContext;
    const hiddenRemoveImgButton = props.hiddenRemoveImgButton;

    const renderImages = () => {
        let newImages = [];
        if (images && images.length > 0) {
            images.forEach((image) => {
                if (image.file) {
                    newImages.push(
                        <div className="images" key={image.context}>
                            <ImageCropView hiddenRemoveImgButton={hiddenRemoveImgButton} onClear={() => doOnClear(image)} imgFile={image.file}/>
                        </div>
                    )
                } else {
                    newImages.push(
                        <div className="images" key={image.context}>
                            <ImageCropView hiddenRemoveImgButton={hiddenRemoveImgButton}  onClear={() => doOnClear(image)} url={image.url}/>
                        </div>
                    )
                }
            });
        }

        return newImages;
    }

    const doOnClear = async (image) => {
        if (deleteCallback) {
            await deleteCallback(image, images, imageContext)
        }
        dispatch(CarouselActions.setLoadImages(true));
    }

    const doAddCallback = async (base64Image, fileImage) => {
        
        if (addCallback) {
            await addCallback(entity, images, base64Image, fileImage, imageContext)
        }
        dispatch(CarouselActions.setLoadImages(true));
    }

    const showAdd = () => {
        if (maxImages === undefined || images === undefined) return true;
        if ((!entity) && (images && images.length === 1)) return false;
        return maxImages > images.length;
    }

    return (
        <div id="addctn" className="container"  >
            <br/>
            <hr/>
            <br/>
            {loading ?
            <>
                <div id="addimglb" className="row">Carregando Imagens...</div>
                <div id="addimgbx" className="box" >
                    <div><ImageDropAndCropLoading /></div>
                </div>
            </>
            :
            <>
                <div id="addimglb" className="row">Adicionar Imagens</div>
                <div id="addimgbx" className="box" >
                    <div id="componente">{showAdd() && <ImageDropAndCrop callback={doAddCallback}/>}</div>
                    {renderImages()}
                </div>
            </>
            }
        </div>
    )
}

export default Carousel;