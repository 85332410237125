import React, {useState} from 'react';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { renderTextField, renderDatePicker } from '../../../components/Fields/Fields';
import { cpfMask } from '../../../services/masks';
import BasicForm from '../../../components/BasicForm';
import StateButton from '../../Settings/components/Candidato/StateButton';

const formName = 'Inscricoes';
const modelName = 'inscricoes';
const label = 'Inscrição';

const validate = (values, props) => {
    const errors = {}

    const requiredFields = [    
        'candidato.cadastropessoal.cpf',    
        'candidato.cadastropessoal.name',
        'concorrencia.name',
        'createdAt',
        'authentication',
        'status'
    ]

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Campo Origatório'
        }
    })

    return errors;
}

let Form = (props) => {
    const [candidatoId, setCandidatoId] = useState('')
    const [concorrencia, setConcorrencia] = useState('')
    const [canDoInscription, setCanDoInscription] = useState(false);
    
    const upper = value => value && value.toUpperCase()

    const afterLoadEntity = (entity) => {
        setCandidatoId(entity.candidato.id);
        setConcorrencia(entity.concorrencia);

        setCanDoInscription(checkResponseInscription(entity.candidato));
    }

    const checkResponseInscription = (data) => {
        if (data.cadastropessoal) {
            if (data.cadastropessoal.estadocivil === 4 || data.cadastropessoal.estadocivil === 6){
            return data.conjugeId && data.enderecoId && data.dadospessoasId && data.demograficoId;
            }
            
            return data.enderecoId && data.dadospessoasId && data.demograficoId;
        }
    }
    
    return (
        <BasicForm name={'Inscricoes'}
            customAfterLoadEntity={afterLoadEntity}
            content={
                <Grid container spacing={3} wrap="wrap">

                    <Grid item xs={4} >
                        <Field name="candidato.cadastropessoal.cpf" type="text" component={renderTextField} label="CPF" normalize={upper} style={{ width: 300 }} maxLength={11} {...cpfMask()} />
                    </Grid>

                    <Grid item xs={8}>
                        <Field name="candidato.cadastropessoal.nome" type="text" component={renderTextField} label="Nome" normalize={upper} style={{ maxWidth: '100%', width: '95%' }} {...{ required: true }} />
                    </Grid>

                    <Grid item xs={2}>
                        <Field name="createdAt" type="date" label="Data de inscrição" component={renderDatePicker} style={{maxWidth: '100%', width: 300}} {...{ required: true }} />
                    </Grid>

                    <Grid item xs={3}>
                        <Field name="authentication" type="text" label="Autenticação" component={renderTextField} style={{maxWidth: '100%', width: 300}} {...{ required: true }} />
                    </Grid>

                    <Grid item xs={3}>
                        <Field name="concorrencia.name" type="text" label="Edital" component={renderTextField} style={{maxWidth: '100%', width: 300}} {...{ required: true }} />
                    </Grid>

                    <Grid item xs={3}>
                        <Field id="status" name="status" component={renderTextField} label="Status" style={{ maxWidth: '100%', width: 300 }}/>
                    </Grid>

                    <StateButton 
                        inscription={true}
                        canDoInscription={canDoInscription}
                        candidatoId={candidatoId}
                        concorrencia={concorrencia}
                    />

                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
})(Form);

export default Form;

