import React from 'react';
import { Field, reduxForm, change} from 'redux-form';
import { validate } from '../../../services';
import { Grid } from '@material-ui/core';
import { renderTextField, renderSelectField } from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';
import { cnpjMask, phoneMask } from '../../../services/masks';
import InputLabel from '@material-ui/core/InputLabel';
import apiCEP from '../../../services/apiCEP';
import MyDivider from '../../../components/MyDivider';

const formName = 'Company';
const modelName = 'companies';
const label = 'Empresas';

let Form = (props) => {

    const upper = value => value && value.toUpperCase()

    const getDataByCEP = (event) => {

        let cep = event.target.value;

        apiCEP.get(`/ws/${cep}/json`).then(
            (data) => {
                props.dispatch(change(formName, 'address', data.data.logradouro.toUpperCase()));
                props.dispatch(change(formName, 'neighborhood', data.data.bairro.toUpperCase()));
                props.dispatch(change(formName, 'city', data.data.localidade.toUpperCase()));
                props.dispatch(change(formName, 'state', data.data.uf.toUpperCase()));
                props.dispatch(change(formName, 'complement', data.data.complemento.toUpperCase()));

            }
        ).catch(err => {
        });       
        
    };

    return (
        <BasicForm
            imageContext={'companies'}
            content={
                <Grid container spacing={3} wrap="wrap">

                    <Grid item xs={12} >
                        <Field name="cnpj" type="text" component={renderTextField} label="CNPJ" normalize={upper} style={{ width: 300 }} maxLength={11} {...cnpjMask()} />
                    </Grid>

                    <Grid item xs={6}>
                        <Field name="name" type="text" component={renderTextField} label="Nome" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>

                    <Grid item xs={6}>
                        <Field name="phone" type="text" component={renderTextField} label="Telefone" normalize={upper} style={{ maxWidth: '100%', width: 300 }} {...phoneMask}/>
                    </Grid>      

                    <Grid item xs={6}>
                        <Field id="status" name="status" component={renderSelectField} label="Status" style={{ maxWidth: '100%', width: 300 }}>
                            <option value="" />
                            <option value={'A'}>ATIVO</option>
                            <option value={'I'}>INATIVO</option>
                            <option value={'P'}>PENDENTE</option>
                        </Field>
                    </Grid>                                        

                    <Grid item xs={12}>
                        <MyDivider/>                      
                    </Grid>

                    <Grid item xs={12}>
                        <Field name="cep" onBlur={getDataByCEP} type="text" component={renderTextField} label="CEP" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>   

                    <Grid item xs={6}>
                        <Field name="address" type="text" component={renderTextField} label="Endereço" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>   

                    <Grid item xs={6}>
                        <Field name="number" type="text" component={renderTextField} label="Número" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>   

                    <Grid item xs={6}>
                        <Field name="neighborhood" type="text" component={renderTextField} label="Bairro" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>                                               

                    <Grid item xs={6}>
                        <Field name="city" type="text" component={renderTextField} label="Cidade" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>   

                    <Grid item xs={6}>
                        <InputLabel>Estado</InputLabel>
                        <Field id="state" name="state" component={renderSelectField} style={{ maxWidth: '100%', width: 300 }}>
                            <option value={'AC'}>Acre</option>
                            <option value={'AL'}>Alagoas</option>
                            <option value={'AP'}>Amapá</option>
                            <option value={'AM'}>Amazonas</option>
                            <option value={'BA'}>Bahia</option>
                            <option value={'CE'}>Ceará</option>
                            <option value={'DF'}>Distrito Federal</option>
                            <option value={'ES'}>Espírito Santo</option>
                            <option value={'GO'}>Goiás</option>
                            <option value={'MA'}>Maranhão</option>
                            <option value={'MT'}>Mato Grosso</option>
                            <option value={'MS'}>Mato Grosso do Sul</option>
                            <option value={'MG'}>Minas Gerais</option>
                            <option value={'PA'}>Pará</option>
                            <option value={'PB'}>Paraíba</option>
                            <option value={'PR'}>Paraná</option>
                            <option value={'PE'}>Pernambuco</option>
                            <option value={'PI'}>Piauí</option>
                            <option value={'RJ'}>Rio de Janeiro</option>
                            <option value={'RN'}>Rio Grande do Norte</option>
                            <option value={'RS'}>Rio Grande do Sul</option>
                            <option value={'RO'}>Rondônia</option>
                            <option value={'RR'}>Roraima</option>
                            <option value={'SC'}>Santa Catarina</option>
                            <option value={'SP'}>São Paulo</option>
                            <option value={'SE'}>Sergipe</option>
                            <option value={'TO'}>Tocantins</option>
                        </Field>
                    </Grid>

                    <Grid item xs={6}>
                        <Field name="complement" type="text" component={renderTextField} label="Complemento" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>
                    
                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues:{'status':'A'}
})(Form);

export default Form;

