import { ToastsStore } from 'react-toasts';

function error(msg) {
    ToastsStore.warning(msg, 5000);
}

function success(msg) {
    ToastsStore.success(msg, 5000);
}

function warning(msg) {
    ToastsStore.info(msg, 5000);
}

const ToastMessage = {
    error: error,
    success: success,
    warning: warning
}

export default ToastMessage;