import React, {useState} from 'react';
import { Field, reduxForm} from 'redux-form';
import { validate } from '../../../services';
import { Grid } from '@material-ui/core';
import { renderTextField } from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';
import UploadFile from '../../../components/UploadFile';

const formName = 'Exame';
const modelName = 'exames';
const label = 'Exames';

let Form = (props) => {
    const [entity, setEntity] = useState({});

    const upper = value => value && value.toUpperCase()
    
    const afterLoadEntity = (entity) => {
        setEntity(entity);
    }

    const afterInitialState = () => {
        setEntity({});
    }

    return (
        <BasicForm
            customAfterLoadEntity={afterLoadEntity}
            keepUpdateAfterSubmit={true}
            customAfterInitialState={afterInitialState}
            content={
                <Grid container spacing={3} wrap="wrap">

                    <Grid item xs={12} >
                        <Field name="requisicao" type="text" component={renderTextField} label="Requisição" normalize={upper} style={{ width: 300 }} />
                    </Grid>

                    <Grid item xs={6}>
                        <Field name="paciente" type="text" component={renderTextField} label="Nome" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>

                    <Grid item xs={6}>
                        <Field name="status_exame" type="text" component={renderTextField} label="Situação" normalize={upper} style={{ maxWidth: '100%', width: 300 }}/>
                    </Grid>

                    <Grid item xs={6}>
                        <Field name="resultado" type="text" component={renderTextField} label="Resultado" normalize={upper} style={{ maxWidth: '100%', width: 300 }}/>
                    </Grid>
                    {entity.id &&
                        <Grid item xs={12}>
                            <UploadFile context="exames" label="Incluir Exame" entity={entity} list={entity.files}></UploadFile>
                        </Grid>
                    }
                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues:{'status':'A'}
})(Form);

export default Form;

