/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { Component, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { List, ListItem, Button, colors, Collapse } from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AuthState from '../../../../../../auth/Auth';

import { withStyles } from '@material-ui/core/styles'

const useStyles = theme => ({
    root: {},
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    button: {
        color: colors.blueGrey[800],
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium
    },
    icon: {
        color: theme.palette.icon,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },
    nested: {
        //paddingLeft: theme.spacing(4),
        paddingLeft: theme.spacing(1),
        display: 'flex',
        paddingRight: 0
    },
    active: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        'icon': {
            color: theme.palette.primary.main,
            width: 50,
            height: 50,
        }
    }
})

const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));

class SidebarNav extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    handleClick(item) {
        this.setState(prevState => (
            { [item]: !prevState[item] }
        ))
    }

    handler(children) {
        const { classes } = this.props
        const { state } = this

        return children.map((subOption) => {
            
            if (!AuthState.allowedTo(subOption.href)) {
                return (<div key={subOption.title}></div>)
            } else 

            if ( subOption.parent==='root'){
                return (
                    <ListItem
                        className={classes.item}
                        disableGutters
                        button
                        key={subOption.title}
                        >
                            
                        <Button
                            activeClassName={classes.active}
                            className={classes.button}
                            component={CustomRouterLink}
                            to={subOption.href}
                        >
                            {<div className={classes.icon}>{subOption.icon}</div>}
                            {subOption.title}
                        </Button>
                    </ListItem>
                )

            } else if (!subOption.children) {
                return (
                    <ListItem
                        className={classes.item}
                        disableGutters
                        button
                        key={subOption.title}
                        style={{marginLeft:15, width:192}}
                        // style={{marginLeft:15, background:'#FFEEEE', width:192}}
                        >
                            
                        <Button
                            activeClassName={classes.active}                            
                            className={classes.button}
                            component={CustomRouterLink}
                            to={subOption.href}
                        >
                            <div className={classes.icon}>{subOption.icon}</div>
                            {subOption.title}
                        </Button>
                    </ListItem>
                )
            } else if (subOption.parent==='') {
                return (
                    <List component="div" disablePadding key={subOption.title}>
                        <ListItem
                            className={classes.nested}
                            button
                            
                            onClick={() => this.handleClick(subOption.title)}>
                                    <div className={classes.icon}>{subOption.icon}</div>
                                    {subOption.title}
                            {state[subOption.title] ?
                                <ExpandLess /> :
                                <ExpandMore />
                            }
                        </ListItem>
                        <Collapse
                            in={state[subOption.title]}
                            timeout="auto"
                            unmountOnExit
                        >
                            {this.handler(subOption.children)}
                        </Collapse>
                    </List>
                )
            } else {
                return (
                    <List component="div" disablePadding key={subOption.title}>
                        <ListItem
                            className={classes.nested}
                            button
                            style={{marginLeft:10, width:192}}
                            onClick={() => this.handleClick(subOption.title)}>
                                    <div className={classes.icon}>{subOption.icon}</div>
                                    {subOption.title}
                            {state[subOption.title] ?
                                <ExpandLess /> :
                                <ExpandMore />
                            }
                        </ListItem>
                        <Collapse
                            in={state[subOption.title]}
                            timeout="auto"
                            unmountOnExit
                        >
                            {this.handler(subOption.children)}
                        </Collapse>
                    </List>
                )

            }
        })
    }

    render() {
        const { classes, pages, rest, className } = this.props
        return (
            <List
            {...rest}
            className={clsx(classes.root, className)}
            >
                {this.handler(pages)}
            </List>
        )
    }
}

SidebarNav.propTypes = {
    className: PropTypes.string,
    pages: PropTypes.array.isRequired
};

export default withStyles(useStyles)(SidebarNav)