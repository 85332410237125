import React, {useState  } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { validate } from '../../../services';
import { Grid , Button } from '@material-ui/core';
import { renderTextField, renderTextAreaField, renderSelectField,  } from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import moment from 'moment';
import { isMobile } from 'react-device-detect';



const formName = 'RegistroAtividadesIndividual';
const modelName = 'registroAtividadesIndividual';
const label = 'Registro de Atividade Individual';

let Form = (props) => {
    const [entity, setEntity] = useState({});

    const {pristine} = props;

    const [position, setPosition] = useState({});
    const [nome, setNome] = useState('');
    
    const [positionEmpresa, setPositionEmpresa] = useState({});
    const [nomeEmpresa, setNomeEmpresa] = useState('PMJP');
    const [value, setValue] = useState("");

    const [center, setCenter] = useState({ lat: -7.111588, lng: -34.850261 });


    const handleChange = (e) => {
        setValue(e.target.value);
        localStorage.setItem("inputValue", e.target.value);
      };

    const MyMapComponent = withScriptjs(withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={12}
            center={center}
            defaultCenter={{ lat: -7.111588, lng: -34.850261 }}
        >
            {position && position.lat &&                 
                <Marker 
                    icon={{url:'/images/MarkerPerson.png' } }
                    // label={{fontWeight: "bold", text: nome.split(' ')[0], color:'#038d31'}} 
                    // options={{anchorPoint:{x:100,y:50}}}
                    position={position}  />   
            }
            {/* {console.log(positionEmpresa)} */}
            {positionEmpresa && positionEmpresa.lat && 
                <Marker 
                    icon={{url:'/images/MarkerEmpresa.png' }}  
                    // label={nomeEmpresa.split(' ')[0]}                     
                    position={positionEmpresa} 
                    // position={{lat: -7.111588, lng: -34.850261}} 
                    
                    />
            }

        </GoogleMap>
    ))
    

    const upper = value => value && value.toUpperCase()
    
    const afterLoadEntity = (entity) => {

        setEntity(entity);  
        setPositionEmpresa({lat: parseFloat(entity.secretaria.latitudeMin) , lng: parseFloat(entity.secretaria.longetudeMin)});
        setNomeEmpresa(entity.secretaria.nome);
        setNome(entity.users.name);
        
        handleGeoLocation();

        let current_time = moment().format("HH:mm:ss");   
        props.dispatch(change(formName, 'status', 'R'));
        props.dispatch(change(formName, 'horaRegistro', current_time));
        props.dispatch(change(formName, 'dia', moment().isoWeekday()));

        if (pristine){
            localStorage.setItem("inputValue", '');
        } else {
            props.dispatch(change(formName, 'comentario', localStorage.getItem("inputValue")));
        }

    }

    moment.locale('pt-br')   

    const handleGeoLocation = () => {
        navigator.geolocation.getCurrentPosition(success, error, options); 
      }  
    
      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };
      
      function success(pos) {
        var crd = pos.coords;
                     
        props.dispatch(change(formName, 'latitude', crd.latitude));
        props.dispatch(change(formName, 'longitude', crd.longitude));
        
        setPosition({lat: crd.latitude, lng: crd.longitude});
        setCenter({lat: crd.latitude, lng: crd.longitude});
        
        // setPositionEmpresa({lat: entity.secretaria.latitude_min, lng: entity.secretaria.longetudeMin});

        // console.log('Sua posição atual é:');
        // console.log('Latitude : ' + crd.latitude);
        // console.log('Longitude: ' + crd.longitude);
        // console.log('Mais ou menos ' + crd.accuracy + ' metros.');
      };
      
      function error(err) {        
        console.warn('ERROR(' + err.code + '): ' + err.message);
      };  
    
    const afterSubmit = (e) =>{
        localStorage.setItem("inputValue", '');

    }  

    return (
        <BasicForm
            customAfterLoadEntity={afterLoadEntity}
            imageContext={'registroAtividades'}
            keepUpdateAfterSubmit={true}
            maxImages={2}
            hiddenNewButton={true}
            customAfterSubmit={afterSubmit}
            routeAfterSubmit={`/home`} 

            // customAfterInitialState={afterInitialState}
            content={
                <Grid container spacing={3} wrap="wrap">

                    <Grid item xs={12}>
                    <div >
    
                        <MyMapComponent
                            isMarkerShown
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAmiTdTowI7DxNIodiBXI0d6JkCqOuGwA0&v=3.exp&libraries=geometry,drawing,places"
                            loadingElement={<div style={{maxWidth: '100%', width: 800 , height: `100%` }} />}
                            containerElement={<div style={{maxWidth: '100%', width: 800 , height: `100%` }} />}
                            center={position}                            
                            mapElement={<div style={{ height: `300px`}} />}
                        />
                        </div>  
                    </Grid>      


                    <Grid item xs={12}>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={handleGeoLocation} 
                            style={{ maxWidth: '100%', width:isMobile?400:350 }}                           
                            >
                            Localização Atual
                        </Button>
                    </Grid>          

                    <Grid item xs={12}>
                        <Field 
                            name="comentario" 
                            type="textarea" 
                            value={value}
                            component={renderTextAreaField} 
                            label="Comentário" 
                            normalize={upper} 
                            style={{ maxWidth: '100%', width:isMobile?350:800 }} 
                            onBlur={handleChange}     
                            />
                    </Grid>

                    <Grid item xs={isMobile?12:6}>
                        <Field disabled={true} name="horaRegistro" type="text" component={renderTextField} label="Hora Registro" normalize={upper} style={{ maxWidth: '100%', width: 350 }} />
                    </Grid>

                     <Grid item xs={isMobile?12:6}>
                        <Field disabled={true} label="dia" name="dia" component={renderSelectField}  style={{maxWidth: '100%', width: 350}}  >
                            <option value={'0'}>Domingo</option>
                            <option value={'1'}>Segunda</option>
                            <option value={'2'}>Terça</option>
                            <option value={'3'}>Quarta</option>
                            <option value={'4'}>Quinta</option>
                            <option value={'5'}>Sexta</option>
                            <option value={'6'}>Sábado</option>                            
                        </Field>
                    </Grid>    
                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues:{
        status:'R',
        dataRegistro: moment().locale('pt-br')
    }
})(Form);

export default Form;

