import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/styles';

import {
  Card,
  CardHeader,
  CardActions,
  Divider,
  Button
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const SadRegistro = props => {

  const { className, ...rest } = props;
  const history = useHistory();

  const classes = useStyles();

  const handleClick = (e) => {
      history.push(`/demandasSimples`);   
  }


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          title="SEPT - Registro de Demandas"
          subheader="Para registrar uma demandas para a prefeitura municipal de João Pessoa Click no botão a baixo <REGISTRAR DEMANDA>"
        />
        <Divider />

        <CardActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleClick}
          >
            Registar Demanda
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

SadRegistro.propTypes = {
  className: PropTypes.string
};

export default SadRegistro;
