import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardActions,
  Divider,
  Button,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import AuthState from '../../../../auth/Auth';
import api from '../../../../services/api';
import moment from 'moment';
import StateButton from './StateButton';

const useStyles = makeStyles(() => ({
  root: {},

}));

const Candidato = props => {
  const { className, ...rest } = props;
  const history = useHistory();
  const classes = useStyles();
  const candidatoId = AuthState.getCandidato();
  const [entity, setEntity] = useState({});
  const [canDoInscription, setCanDoInscription] = useState(false);
  const [entityConcorrencia, setEntityConcorrencia] = useState({});
  const [inscription, setInscription] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    history.push(`/candidatos/${candidatoId}`)
  }

  const checkResponseInscription = (data) => {
    if (data.cadastropessoal) {
      if (data.cadastropessoal.estadocivil === 4 || data.cadastropessoal.estadocivil === 6){
        return data.conjugeId && data.enderecoId && data.dadospessoasId && data.demograficoId;
      }
      
      return data.enderecoId && data.dadospessoasId && data.demograficoId;
    }
  }

  const onSuccess = (inscription) => {
    setInscription(inscription);
  }

  const getInfoCandidato = () => {
    if (candidatoId){
      api.get(`candidatos/${candidatoId}`).then(response => {
        setEntity(response.data);
  
        setCanDoInscription(checkResponseInscription(response.data));
      });
    }

  }

  const getInfoConcorrencia = () => {
    var today = moment().format('YYYY-MM-DD');
    
    api.get(`/concorrencias/valida/${today}`)
      .then(response => {  
        setEntityConcorrencia(response.data); 
    });
  }

  const getInfoComprovante = (candidatoId, concorrenciaId) => {
    if (canDoInscription) {
      api.get(`/candidatos/inscricoes/${candidatoId}`, { params: { concorrenciaId: concorrenciaId }})
      .then(response => {
        if (response.data && response.data.concorrencia) {
          setInscription(true);
        }
      });
    }
  }

  useEffect(() => {
    getInfoCandidato();
    getInfoConcorrencia();
  }, []);

  useEffect(() => {
    getInfoComprovante(entity.id, entityConcorrencia.id);
  }, [canDoInscription, entityConcorrencia]);

  return (
    <>
      {
        candidatoId &&
        <Card
          {...rest}
          className={clsx(classes.root, className)}
        >
          <form>
            <CardHeader
              subheader="Atualizar/Alterar dados do cadastro pessoal "
              title="Secretaria de Habitação - Dados para Concorrência de Habitação Popular"

            />
            <Typography color="textSecondary" style={{ marginLeft: 15 }} variant="h5" component="h2">
              Instruções: Após o preenchimento de todos os dados na ficha de cadastro, é necessário confirmar a
              inscrição para que seja gerado o comprovante de inscrição.
            </Typography>

            <Divider style={{ marginTop: 10 }} />
            <Divider style={{ marginTop: 10 }} />
            <CardActions>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleClick}
              >
                Alterar
              </Button>

              <StateButton 
                inscription={inscription}
                canDoInscription={canDoInscription}
                candidatoId={candidatoId}
                concorrencia={entityConcorrencia}
                onSuccess={onSuccess}
              />
            </CardActions>
          </form>
        </Card>
      }
    </>
  );
};

Candidato.propTypes = {
  className: PropTypes.string
};

export default Candidato;
