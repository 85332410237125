import validEmail from '../../services/validEmail';

const validate = values => {
    const errors = {};
    var requiredFields = ['email'];

    // VALIDAÇAO CAMPOS REQUIRE
    requiredFields.forEach(field => {
        if (!values[field]) errors[field] = 'Campo obrigatório';
    })

    if (!errors['email']) return validEmail(values);

    return errors;
}

export default validate;