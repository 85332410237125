import React from 'react';


let MyDivider = () => {

    return (
        <div>
            <br></br>
            <center>
            <hr style={{ maxWidth: '100%', width: 450, borderColor:'#F00', borderTop:5 }} ></hr>
            </center>            
        </div>        
    );
};

export default MyDivider;

