
import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

const {Types, Creators} = createActions({
    setFilter:['value'],
    setLoadMasterDetail: ['value'],

});

export const GridListTypes = Types;

const INITIAL_STATE = Immutable({
    filter: [],
    loadMasterDetail: false,
});

export const reducer = createReducer(INITIAL_STATE,{
    [Types.SET_FILTER]: (state, action) => {return state.merge({filter:action.value})},
    [Types.SET_LOAD_MASTER_DETAIL]: (state, action) => {return state.merge({loadMasterDetail:action.value})},
});

export default Creators;
