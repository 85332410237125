import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Grid, Link } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { useParams, useHistory } from "react-router-dom";

import useStyles from './resetPasswordFormStyle';
import validate from './resetPasswordFormHelper';

import { renderTextField } from '../Fields/Fields';
import api from '../../services/api';
import { ToastMessage } from '../../layouts';


let RestePasswordForm = (props) => {
    const { handleSubmit, submitting } = props;
    const { token } = useParams();
    const history = useHistory();

    const classes = useStyles();

    const sendEmail = ({password}) => {
        return api.post(`forgotPassword/${token}`, {password})
        .then(response => {
            if (response.status === 200) {
                history.push("/resetPassword/sucesso");
            } else {
                ToastMessage.warning(response.message);
            }
        })
    }


    return (
        <form name="resetPasswordForm" onSubmit={handleSubmit(sendEmail)}>
                <div className={classes.mainForm}>
                    <Grid container className={classes.container} wrap="wrap">
                                               
                        <Grid xs={12} className={classes.mainRight} >
                            
                            <div className={classes.title}>
                                <strong> Recuperação de senha </strong>
                            </div>
                            <Divider style={{ height: 3 }}></Divider>

                            <Grid item xs={10} className={classes.password}>
                                <Field name="password" type="password" component={renderTextField} style={{width: 450}} label="Senha"/>
                            </Grid>
                            <Grid item xs={10} className={classes.confirmPassword}>
                                <Field name="confirmPassword" type="password" component={renderTextField} style={{width: 450}} label="Confirmar senha"/>
                            </Grid>

                            <Divider style={{ height: 3 }}></Divider>

                            <Button
                                color="primary"
                                variant="outlined"
                                disabled={submitting}
                                type="submit"
                                className={classes.submitButton}
                            >
                                Alterar
                            </Button>

                            <Link href="/login" className="">
                                <Button
                                    color="primary"
                                    type="button"
                                    variant="contained"
                                    className={classes.backButton}
                                >
                                    Voltar
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </div>
            </form>
    );
}

RestePasswordForm = reduxForm({
    form: 'RestePasswordForm',
    validate
})(RestePasswordForm);

export default RestePasswordForm;