import React from 'react';

const EstadoCivilOptions = () => {
    return <>
        <option></option>
        <option key={1} value={1}>Solteiro(a)</option>
        <option key={2} value={2}>Divorciado(a)</option>
        <option key={3} value={3}>Separado(a)</option>
        <option key={4} value={4}>União Estável</option>
        <option key={5} value={5}>Viúvo(a)</option>
        <option key={6} value={6}>Casado(a)</option>
    </>;
}

export default EstadoCivilOptions;