import React from 'react';
import { change, Field, reduxForm } from 'redux-form';
import { Grid, Divider, CardContent, CardHeader, Card } from '@material-ui/core';
import { renderTextField, renderSelectField, renderTextAreaField } from '../../../../components/Fields/Fields';
import BasicForm from '../../../../components/BasicForm';
import { cepMask, numberMaskNoDescimal } from '../../../../services/masks';
import SituacaoHabitacionalOptions from '../../components/SituacaoHabitacionalOptions';

import FormTabs from '../../FormTabs';
import { useSelector , useDispatch} from 'react-redux';
import FormCandidatoActions from '../../../../store/ducks/FormCandidato';
import '../../required-field.css';
import apiCEP from '../../../../services/apiCEP';
import { ToastMessage } from '../../../../layouts';

const formName = 'Endereco';
const modelName = 'enderecos';
const label = 'Endereco';

const isRequired = () => {
    return {required: true}
}

const validate = (values) => {
    const errors = {}

    const requiredFields = [
        'rua',
        'numero',
        'bairro',
        'temporesidejp',
        'temposituacaohabitacional',
        'situacaohabitacional'
    ]

    if (values.resideocupacao === 'SIM') {
        requiredFields.push('comunidade')
    }

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Campo Origatório'
        }
    })

    if (values['temporesidejp'] && values['temporesidejp'] > 99) {
        errors.temporesidejp = 'Tempo que Reside em João Pessoa (anos) inválido: intervalo (0 a 99)';
    }

    if (values['temposituacaohabitacional'] && values['temposituacaohabitacional'] > 99) {
        errors.temposituacaohabitacional = 'Tempo que encontra-se em habitação (anos) inválido: intervalo (0 a 99)';
    }    

    return errors;
}

let Form = (props) => {
    
    const dispatch = useDispatch();
    const formCandidatoData = useSelector(state => state.formCandidato.data); 

    dispatch(FormCandidatoActions.setFormName({formName:formName}));   

    const upper = value => value && value.toUpperCase()

    const beforeSubmit = (values) => {
        values.candidatoId = formCandidatoData.id;
    }

    const getDataByCEP = (event) => {
        const numberPattern = /\d+/g;
        let cep = (event.target.value.match( numberPattern ) || []).join("");

        if (cep) {
            apiCEP.get(`/ws/${cep}/json`)
            .then(data => {
                const values = data.data;
                props.dispatch(change(formName, 'rua', values.logradouro.toUpperCase()));
                props.dispatch(change(formName, 'bairro', values.bairro.toUpperCase()));
                props.dispatch(change(formName, 'complemento', values.complemento.toUpperCase()));
            })
            .catch(error => {
                console.error(error);
                ToastMessage.error('CEP não encontrado');
            })
        }
    }

    return (
        <>
            <FormTabs />
            <BasicForm
                routeAfterSubmit={`/candidatos/${formCandidatoData.id}/endereco/:id`}
                customBeforeSubmit={beforeSubmit}
                hiddenNewButton={true}  
                content={
                    <Grid container wrap="wrap" className="row">
                        <Card className="personalData">
                            <CardHeader title="Informação Residencial"/>
                            <CardContent>
                                <Grid container spacing={2} wrap="wrap">
                                    <Grid item xs={12}>
                                        <Field name="cep" onBlur={getDataByCEP} type="text" component={renderTextField} label="CEP" style={{ width: 250 }} {...cepMask}/>
                                    </Grid>

                                    <Grid item xs={9}>
                                        <Field name="rua" type="text" component={renderTextField} label="Rua"style={{ maxWidth: '100%', width: 600 }}  normalize={upper} {...isRequired()}/>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Field name="numero" type="text" component={renderTextField} label="Número" style={{ maxWidth: '100%', width: 150 }}  normalize={upper} {...isRequired()}/>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Field name="bairro" type="text" component={renderTextField} label="Bairro" style={{ maxWidth: '100%', width: 350 }} normalize={upper} {...isRequired()}/>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Field label="Situacao Habitacional" name="situacaohabitacional" component={renderSelectField} style={{ maxWidth: '100%',width: 350 }} {...isRequired()}>
                                            <SituacaoHabitacionalOptions/>
                                        </Field>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Field name="referencia" type="text" component={renderTextAreaField} label="Ponto de Referência" style={{ maxWidth: '100%',width: 350 }} normalize={upper}/>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Field name="observacao" type="text" component={renderTextAreaField} label="Complemento" style={{ maxWidth: '100%', width: 350 }} normalize={upper}/>
                                    </Grid>                                    
                                    
                                    <Grid item xs={6}>
                                        <Field name="temporesidejp" type="text" component={renderTextField} label="Tempo que Reside em João Pessoa (anos)" style={{ maxWidth: '100%',width: 350 }} normalize={upper} {...numberMaskNoDescimal} {...isRequired()}/>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Field name="temposituacaohabitacional" type="text" component={renderTextField} label="Tempo que se Encontra Nessa Situação (anos)" style={{ maxWidth: '100%',width: 350 }} normalize={upper} {...numberMaskNoDescimal} {...isRequired()}/>
                                    </Grid>  

                                    <Grid item xs={4}>
                                        <Field label="Reside em Ocupação" name="irregulacomunidade" component={renderSelectField} style={{ maxWidth: '100%', width: 230 }}>
                                            <option key={1} value={1}>SIM</option>
                                            <option key={2} value={2}>NÃO</option>
                                        </Field>
                                    </Grid>

                                    <Grid item xs={8}>
                                        <Field name="nomeirregulacomunidade" type="text" component={renderTextField} label="Nome da Ocupação/comunidade" style={{  maxWidth: '100%', width: 475 }} normalize={upper}/>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Field label="Participante de Mov. de Moradia" name="participamovimento" component={renderSelectField} style={{maxWidth: '100%', width: 230 }}>
                                            <option value={'SIM'}>SIM</option>
                                            <option value={'NÃO'}>NÃO</option>
                                        </Field>
                                    </Grid>

                                    <Grid item xs={8}>
                                        <Field name="movimento" type="text" component={renderTextField} label="Nome Entidade/Movimento" style={{ maxWidth: '100%',width: 475 }} normalize={upper}/>
                                    </Grid>     

                                    <Grid item xs={4}>
                                        <Field label="Algum dos proponentes já possui imóvel próprio" name="possuiuimovel" component={renderSelectField} style={{maxWidth: '100%', width: 230 }}>
                                            <option value={'SIM'}>SIM</option>
                                            <option value={'NÃO'}>NÃO</option>
                                        </Field>
                                    </Grid>

                                    <Grid item xs={8}>
                                        <Field name="imoveldesfez" type="text" component={renderTextAreaField} label="Motivo pelo qual se desfaz" style={{maxWidth: '100%', width: 475 }} normalize={upper}/>
                                    </Grid>

                                </Grid>

                            </CardContent>
                        </Card>

                        <Divider />

                    </Grid>
                }
                formName={formName}
                modelName={modelName}
                label={label}
                { ...props }
            />
        </>
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues:{
        'irregulacomunidade':2,
        'nomeirregulacomunidade':'NÃO',
        'participamovimento':'NÃO',
        'possuiuimovel': 'NÃO'
    }
})(Form);

export default Form;

