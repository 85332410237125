import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Grid, InputLabel } from '@material-ui/core';
import { renderTextField } from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';
import moment from 'moment';

const formName = 'Permissão';
const modelName = 'grants';
const label = 'Permissões';

const validate = (values, props) => {
    const errors = {}

    const requiredFields = [
        'validate',
        'grant',
        'route',
    ]

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Campo Origatório'
        }
    })

    return errors;
}

let Form = (props) => {
    const upper = value => value && value.toUpperCase();
    const lower = value => value && value.toLowerCase();

    return (
        <BasicForm
            content={
                <Grid container spacing={3} wrap="wrap">

                    <Grid item xs={12} >
                        <Field name="grant" type="text" component={renderTextField} label="Permissão" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>

                    <Grid item xs={12} >
                        <InputLabel>Data de validade</InputLabel>
                        <Field name="validate" type="date" component={renderTextField} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>

                    <Grid item xs={12}>
                        <Field name="note" type="text" component={renderTextField} label="Nota" style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>

                    <Grid item xs={6}>
                        <Field name="route" type="text" component={renderTextField} label="Rota" style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>

                    <Grid item xs={6}>
                        <Field name="filterableRoute" type="text" component={renderTextField} label="Filtragem de Rota" style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>                    

                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues: {
        'validate': moment().format('YYYY-MM-DD')
    }
})(Form);

export default Form;

