
import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

const {Types, Creators} = createActions({
    setData:['value'],
    setFormName:['value'],

});

export const GridListTypes = Types;

const INITIAL_STATE = Immutable({
    data: [],
    formName:[],
});

export const reducer = createReducer(INITIAL_STATE,{
    [Types.SET_DATA]: (state, action) => {return state.merge({data:action.value})},    
    [Types.SET_FORM_NAME]: (state, action) => {return state.merge({formName:action.value})},    
});

export default Creators;
