import React from 'react';

const LocalTrabalhoOptions = () => {
    return <>
        <option></option>
        <option key={0} value={0}>Água Fria</option>
        <option key={1} value={1}>Aeroclube</option>
        <option key={2} value={2}>Altiplano</option>
        <option key={3} value={3}>Alto do Céu</option>
        <option key={4} value={4}>Alto do Mateus</option>
        <option key={5} value={5}>Anatólia</option>
        <option key={6} value={6}>Bairro das Indústrias</option>
        <option key={7} value={7}>Bairro dos Estados</option>
        <option key={8} value={8}>Bairro dos Ipês</option>
        <option key={9} value={9}>Bairro dos Novais</option>
        <option key={10} value={10}>Bancários</option>
        <option key={11} value={11}>Barra de Gramame</option>
        <option key={12} value={12}>Bessa</option>
        <option key={13} value={13}>Jardim Brisamar</option>
        <option key={14} value={14}>Cabo Branco</option>
        <option key={15} value={15}>Castelo Branco</option>
        <option key={16} value={16}>Centro</option>
        <option key={17} value={17}>Cidade dos Colibris</option>
        <option key={18} value={18}>Costa do Sol</option>
        <option key={19} value={19}>Costa e Silva</option>
        <option key={20} value={20}>Cristo Redentor</option>
        <option key={21} value={21}>Cruz das Armas</option>
        <option key={22} value={22}>Cuiá</option>
        <option key={23} value={23}>Distrito Industrial</option>
        <option key={24} value={24}>Ernesto Geisel</option>
        <option key={25} value={25}>Ernâni Sátiro</option>
        <option key={26} value={26}>Expedicionários</option>
        <option key={27} value={27}>Funcionários II</option>
        <option key={28} value={28}>Funcionários III</option>
        <option key={29} value={29}>Gramame</option>
        <option key={30} value={30}>Grotão</option>
        <option key={31} value={31}>Ilha do Bispo</option>
        <option key={32} value={32}>Jaguaribe</option>
        <option key={33} value={33}>Jardim 13 de Maio</option>
        <option key={34} value={34}>Jardim Cidade Universitária</option>
        <option key={35} value={35}>Jardim Esther</option>
        <option key={36} value={36}>Jardim Luna</option>
        <option key={37} value={37}>Jardim Mangueira</option>
        <option key={38} value={38}>Jardim Oceania</option>
        <option key={39} value={39}>Jardim Planalto</option>
        <option key={40} value={40}>Jardim São Paulo</option>
        <option key={41} value={41}>Jardim Veneza</option>
        <option key={42} value={42}>José Américo</option>
        <option key={43} value={43}>João Agripino</option>
        <option key={44} value={44}>João Paulo II</option>
        <option key={45} value={45}>Manaíra</option>
        <option key={46} value={46}>Mandacaru</option>
        <option key={47} value={47}>Mangabeira</option>
        <option key={48} value={48}>Mata do Buraquinho</option>
        <option key={49} value={49}>Miramar</option>
        <option key={50} value={50}>Mumbaba</option>
        <option key={51} value={51}>Mussuré</option>
        <option key={52} value={52}>Muçumagro</option>
        <option key={53} value={53}>Oitizeiro</option>
        <option key={54} value={54}>Padre Zé</option>
        <option key={55} value={55}>Paratibe</option>
        <option key={56} value={56}>Pedro Gondim</option>
        <option key={57} value={57}>Penha</option>
        <option key={58} value={58}>Planalto da Boa Esperança</option>
        <option key={59} value={59}>Ponta dos Seixas</option>
        <option key={60} value={60}>Portal do Sol</option>
        <option key={61} value={61}>Quadramares[4]</option>
        <option key={62} value={62}>Rangel</option>
        <option key={63} value={63}>Róger</option>
        <option key={64} value={64}>São José</option>
        <option key={65} value={65}>Tambaú</option>
        <option key={66} value={66}>Tambauzinho</option>
        <option key={67} value={67}>Tambiá</option>
        <option key={68} value={68}>Torre</option>
        <option key={69} value={69}>Trincheiras</option>
        <option key={70} value={70}>Valentina Figueiredo</option>
        <option key={71} value={71}>Varadouro</option>
        <option key={72} value={72}>Varjão</option>
        <option key={73} value={73}>outro</option>
    </>;
}

export default LocalTrabalhoOptions;