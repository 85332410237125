import React, {useState, useEffect} from 'react';
import { Field, reduxForm } from 'redux-form';
import validCpf from '../../../../services/validCpf';
import { Grid, Divider, CardContent, CardHeader, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { renderTextField, renderSelectField, renderDatePicker } from '../../../../components/Fields/Fields';
import BasicForm from '../../../../components/BasicForm';
import { cpfMask, moneyMask, phoneMask } from '../../../../services/masks';
import api from '../../../../services/api';
import LocalTrabalhoOptions from '../../components/LocalTrabalhoOptions';
import EstadoOptions from '../../components/EstadoOptions';
import EscolaridadeOptions from '../../components/EscolaridadeOptions';
import EstadoCivilOptions from '../../components/EstadoCivilOptions';
import TipoDeVinculoOptions from '../../components/TipoDeVinculoOptions';

import '../../required-field.css';

const formName = 'Candidato';
const modelName = 'public/candidatos';
const label = 'Candidato';

const validate = values => {
    const errors = {}
    var requiredFields = [
        'nome',
        'cpf',
        'rg',
        'email',
        'rgestado',
        'rgorgao',
        'datanascimento',
        'estadocivil',
        'ocupacaoId',
        'tipovinculo',
        'rendabruta',
        'celular'
    ]

    if (values.beneficio) {
        requiredFields.push('valorbeneficio');
    }


    // VALIDAÇAO CAMPOS REQUIRE
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Campo obrigatório'
        }
    })

    // VALIDAÇAO EMAIL
    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors['email'] = 'Endereço de Email invalido'
    }
    
    // VALIDAÇAO CPF
    if (values.cpf && !validCpf(values.cpf)) {
        errors['cpf'] = 'Cpf inválido';
    }

    return errors
}

const useStyles = makeStyles(theme => ({
    root: {
        margin: "auto",
        // maxWidth: "100%",
        padding: theme.spacing(4),
        width: '100%',
        marginLeft: '10%'
    },
    content: {
        paddingTop: 150,
        textAlign: 'center'
    },
    main:{
        margin: "auto",
        maxWidth: "100%",
      }
}));

  
let Form = (props) => {
    const [ocupacaoList, setOcupacaoList] = useState([]);
    const classes = useStyles();
    const upper = value => value && value.toUpperCase()

    const getOcupacaoList = () =>  {
            api.get('public/candidatos/ocupacao?pageSize=-1').then(response => {
                let list = [];
                response.data.rows.forEach((entity) => {
                    list.push({name: entity.nome, value: entity.id})                    
                });
                setOcupacaoList(list);
            });
    }

    const renderOcupacaoSelection = () => {
        if (ocupacaoList && ocupacaoList.length > 0) {
            let list = [<option key={0} value={0}></option>];
            ocupacaoList.forEach((ocupacao) => {
                list.push(<option key={ocupacao.value} value={ocupacao.value}>{ocupacao.name}</option>);
            })
            return (list);
        }
    }

    useEffect(() => {
        getOcupacaoList();
    }, []);

    return (
        <div className={classes.root}>
            <Grid container justify="center" spacing={12} >
                <Grid item lg={12} xs={12}>
                    <Grid>
                        <BasicForm
                            routeAfterSubmit="/minhahabitacao/sucesso"
                            needRecaptch={true}
                            content={
                                <Grid container wrap="wrap" className="row">
                                    <Card className="personalData">
                                        <CardHeader title="Dados do Titular"/>
                                        <CardContent>
                                            <Grid container spacing={2} wrap="wrap">
                                                <Grid item xs={12} >
                                                    <Field name="cpf" type="text" component={renderTextField} label="CPF" normalize={upper} style={{ width: 300 }} maxLength={11} {...{required: true }} {...cpfMask()} />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Field name="nome" type="text" component={renderTextField} label="Nome" normalize={upper} style={{ maxWidth: '100%', width: '95%' }} {...{ required: true }} />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Field label="Email" name="email" component={renderTextField} style={{maxWidth: '100%', width: '350px'}} {...{ required: true }}/>
                                                </Grid>

                                                <Grid item xs={3}>
                                                    <Field name="datanascimento" type="date" label="Data de nascimento" component={renderDatePicker} style={{maxWidth: '100%', width: 300}} {...{ required: true }} />
                                                </Grid>

                                                <Grid item xs={3}>
                                                    <Field label="Gênero" name="genero" component={renderSelectField}  style={{maxWidth: '100%', width: 150}} >
                                                        <option></option>
                                                        <option value={'Masculino'}>Masculino</option>
                                                        <option value={'Feminino'}>Feminino</option>
                                                    </Field>
                                                </Grid>                                    

                                                <Grid item xs={6}>
                                                    <Field name="rg" type="text" component={renderTextField} label="RG" normalize={upper} maxLength={11} style={{maxWidth: '100%', width: 350}} {...{ required: true }}/>
                                                </Grid>

                                                <Grid item xs={3}>
                                                    <Field name="rgorgao" type="text" component={renderTextField} label="Órgão Emissor" normalize={upper} maxLength={11} style={{maxWidth: '100%', width: 300}} {...{ required: true }}/>
                                                </Grid>

                                                <Grid item xs={3}>
                                                    <Field label="Estado" name="rgestado" component={renderSelectField} style={{maxWidth: '100%', width: 150}} {...{ required: true }}>
                                                        <EstadoOptions />
                                                    </Field>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Field label="Estado Civil" name="estadocivil" component={renderSelectField} style={{maxWidth: '100%', width: 350}} {...{ required: true }}>
                                                        <EstadoCivilOptions />
                                                    </Field>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Field name="documentoestadocivil" type="text" component={renderTextField} label="Docum. de estado Civil" normalize={upper} maxLength={11} style={{maxWidth: '100%', width: 350}}/>
                                                </Grid>                                    

                                                <Grid item xs={3}>
                                                    <Field label="Escolaridade" name="escolaridade" component={renderSelectField} style={{maxWidth: '100%',width: 350}}>
                                                        <EscolaridadeOptions />
                                                    </Field>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>

                                    <Divider />
                                    
                                    <Card className='cardPersonalData'>
                                        <CardHeader title="Dados Profissionais"/>
                                        <CardContent>
                                            <Grid container spacing={3} wrap="wrap">
                                                <Grid item xs={6}>
                                                    <Field id="ocupacaoId" name="ocupacaoId" component={renderSelectField} label="Ocupacao" style={{maxWidth: '100%', width: 350}} {...{ required: true }}>
                                                        {renderOcupacaoSelection()}
                                                    </Field>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Field label="Tipo de Vínculo" name="tipovinculo" component={renderSelectField} style={{maxWidth: '100%', width: 350}} {...{ required: true }}>
                                                        <TipoDeVinculoOptions />
                                                    </Field>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Field label="Localização do Bairro do Trabalho" name="localtrabalho" component={renderSelectField} style={{ maxWidth: '100%', width: 350 }}>
                                                        <LocalTrabalhoOptions />
                                                    </Field>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Field name="rendabruta" type="text" component={renderTextField} label="Renda Bruta" {...moneyMask} style={{maxWidth: '100%', width: 350}} {...{ required: true }}/>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Field name="beneficio" type="text" component={renderTextField} label="Benefício Governamental"  style={{ maxWidth: '100%', width: 350 }}/>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Field name="valorbeneficio" type="text" component={renderTextField} label="Valor mensal" {...moneyMask} style={{maxWidth: '100%', width: 350}}/>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Field name="valorfaixa" type="text" component={renderSelectField} label="Faia de Renda" style={{maxWidth: '100%', width: 350}}>
                                                        <option value={1}>R$0,00 a R$1.800,00</option>
                                                        <option value={2}>R$1.801,00 a R$ 2.600,00</option>
                                                    </Field>
                                                </Grid>

                                                <Divider />

                                                <Grid item xs={12}>
                                                    <CardHeader title="Informações de Cadastro Único"/>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field label="Tem Cadastro Único" name="cadastrounico" component={renderSelectField} style={{maxWidth: '100%',width: 350}} >
                                                        <option value={'SIM'}>Sim</option>
                                                        <option value={'NÃO'}>Não</option>
                                                    </Field>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Field label="Númerodo Cadastro Único (NIS)" name="numerocadastrounico" component={renderTextField} style={{ maxWidth: '100%', width: 350 }}/>
                                                </Grid>                                    

                                            </Grid>
                                        </CardContent>
                                    </Card>

                                    <Card className='cardPersonalData'>
                                        <CardHeader title="Contatos"/>
                                            <CardContent>
                                            <Grid container spacing={2} wrap="wrap">
                                                <Grid item xs={4}>
                                                    <Field label="Celular" name="celular" component={renderTextField} {...phoneMask} style={{ maxWidth: '100%', width: 350 }} {...{ required: true }}/>
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Field label="Telefone Residencial" name="residencial" component={renderTextField} {...phoneMask} style={{ maxWidth: '100%', width: 350 }}/>
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Field label="Telefone Comercial" name="comercial" component={renderTextField} {...phoneMask} style={{ maxWidth: '100%', width: 350 }}/>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            }
                            formName={formName}
                            modelName={modelName}
                            label={label}
                            { ...props }
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues:{'status':'A'}
})(Form);

export default Form;

