import React, {Component} from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ZoomIcon from '@material-ui/icons/ZoomIn';
import './image-drop-box.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Viewer from 'react-viewer';

class ImageCropView extends Component {
    constructor(props){
        super(props)
        this.state = {
            imgSrc: null,
            imgFile: props.imgFile,
            onClear: props.onClear,
            url: props.url,
            hiddenRemoveImgButton: props.hiddenRemoveImgButton || false,
            visible: false,
            activeIndex: 0,
            mode: 'modal',
                        
        }
    }
  
    componentDidMount() {
        const {imgFile} = this.state;
        if (imgFile) {
            var reader = new FileReader();
            reader.readAsDataURL(imgFile);
            reader.onloadend = function (e) {
                this.setState({imgSrc: [reader.result]})
              }.bind(this);    
        }
    }

    doOnClear() {
        const {onClear} = this.state;
        confirmAlert({
            title: 'Confirma exclusão',
            message: 'Tem certeza que deseja excluir essa imagem?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        if (onClear) {
                            onClear();
                        }                
                    }
                },
                {
                    label: 'Não'
                }
            ]
        });
    }

    doOnZoom() {
        this.setState({
            visible: true,
            activeIndex: 0,
          });
    }


    render() {

        let inline = this.state.mode === 'inline';

        const {onClear, hiddenRemoveImgButton} = this.state;

        return (
            <div className="img-drop-box">
                <img 
                    className="centered-image"
                    alt={this.state.url}
                    src={this.state.url || this.state.imgSrc} />
                {
                    onClear && 
                    <div className="img-div">
                        {!hiddenRemoveImgButton &&
                        <Tooltip title="Remover" className="float-button" style={{marginTop:12}}>
                            <IconButton aria-label="Delete" color="primary" variant="contained" onClick={this.doOnClear.bind(this)} component="span">
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        }

                        <Tooltip title="Zoom" className="float-button" style={{marginTop:12}}>
                            <IconButton aria-label="Zoom" color="primary" variant="contained" onClick={this.doOnZoom.bind(this)} component="span">
                                <ZoomIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>  

                        <Viewer
                            visible={this.state.visible}
                            onClose={() => {
                                this.setState({ visible: false });
                            }}
                        images={ [{src: this.state.url , alt:"Ampliar", downloadUrl: this.state.url}] }
                        activeIndex={0}
                        container={inline ? this.container : null}
                        downloadable
                        customToolbar={(toolbars) => {
                            return toolbars.concat([{
                                key: 'test',
                                render: <div>C</div>,
                            onClick: (activeImage) => {
                                },
                                }]);
                            }}
                        // {...options}
                        />
                    </div>
                }
            </div>
        )
    }
}

export default ImageCropView;