import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { renderTextField, renderCheckbox, renderMultiSelectSearchField } from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';
import api from '../../../services/api';

const formName = 'Perfil';
const modelName = 'profiles';
const label = 'Perfis';

const validate = values => {
    const errors = {}
    var requiredFields = [
        'name',
        'description'
    ]

    // VALIDAÇAO CAMPOS REQUIRE
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Campo obrigatório'
        }
    })

    return errors
}

let Form = (props) => {
    const {reset} = props;
    const [grantList, setGrantList] = useState([]);
    const [loadingGrant, setLoadingGrant] = useState(false);
    const [grants, setGrants] = useState([]);

    const upper = value => value && value.toUpperCase();

    const getGrantList = () => {
        if (!loadingGrant) {
            let grants = []
            setLoadingGrant(true);
            api.get('grants?pageSize=-1').then(list => {
                list.data.rows.forEach((row) => grants.push({name: row.grant, value: row.id}))
                setGrantList(grants);
            });
        }
    }

    const afterLoadEntity = (entity) => {
        if (entity && entity.grantIds) {
            let list = [];
            entity.grantIds.forEach((h) => list.push({name: h.grant, value: h.id}))
            setGrants(list);
        }
    }

    const afterInitialState = () => {
        setGrants([]);
        setGrantList([]);
        reset();
    }

    // necessário para atualizar a lista de housingUnits da entidade residents, pois
    // o componente renderMultiSelectSearchField não consegue gerenciar essa informação
    const onChange = (values) => {
        setGrants(values);
    }
    
    const setGrantsToSend = values => {
      values.grants = [];
      
      values.grantIds.forEach((grantId) => values.grants.push(grantId.value));
    }

    getGrantList();

    return (
        <BasicForm
            customAfterLoadEntity={afterLoadEntity}
            customAfterInitialState={afterInitialState}
            customBeforeSubmit={setGrantsToSend}
            content={
                <Grid container spacing={3} wrap="wrap">

                    <Grid item xs={12} >
                        <Field name="name" type="text" component={renderTextField} label="Nome" normalize={upper} style={{ width: 450 }} />
                    </Grid>

                    <Grid item xs={12} >
                        <Field name="description" type="text" component={renderTextField} label="Descrição" style={{ width: 450 }} />
                    </Grid>

                    <Grid item xs={12}>
                        <Field name="isAdmin" type="checkbox" component={renderCheckbox} label="Administrador" style={{ width: 450 }} />
                    </Grid>

                    {/* <Grid item xs={12}>
                        <InputLabel>Permissões</InputLabel>
                        <Field name="grants" type="select" component={renderSelectField} multiple>
                            {renderGrantSelection()}
                        </Field>
                    </Grid> */}
                    <Grid item xs={12}>
                            <Field
                                name="grantIds"
                                id="grantIds"
                                component={renderMultiSelectSearchField}
                                label="Permissões"
                                style={{ width: 450 }}
                                fullWidth
                                {...{options: grantList, defaultValue: grants, customChange: onChange}}
                            />
                    </Grid>

                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate
})(Form);

export default Form;

