const validate = values => {
    const errors = {};
    var requiredFields = ['password', 'confirmPassword'];

    // VALIDAÇAO CAMPOS REQUIRE
    requiredFields.forEach(field => {
        if (!values[field]) errors[field] = 'Campo obrigatório';
    })

    if (values.password && values.confirmPassword) {
        if (values.password.length < 6) {
            errors.password = 'Sua senha deve ter no mínimo 6 carecteres'
        } else if (values.password !== values.confirmPassword) {
            errors.confirmPassword = 'Senhas divergentes'
        }
    }

    return errors;
}

export default validate;