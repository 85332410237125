const validate = values => {
    const errors = {}
    var requiredFields = [
        // 'email',
        'type',
        // 'birth_date',
        'phone',
        'name',
        'cpf',
        // 'rg',

    ]
    if (values.type === "D") {
        requiredFields.push('holder_id');
    }


    // VALIDAÇAO CAMPOS REQUIRE
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Campo obrigatório'
        }
    })

    // VALIDAÇAO EMAIL
    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = 'Endereço de Email invalido'
    }


    // VALIDAÇAO CPF
    var Soma;
    var Resto;
    var i;
    Soma = 0;
    var strCPF = values.cpf === undefined ? '' : values.cpf;
    if (strCPF === "00000000000") {
        errors.cpf = 'CPF invalido';
    } else {
        for (i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) {
            Resto = 0;
        }
        if (Resto !== parseInt(strCPF.substring(9, 10))) {
            errors.cpf = 'CPF invalido'
        } else {
            Soma = 0;
            for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
            Resto = (Soma * 10) % 11;
            if ((Resto === 10) || (Resto === 11)) {
                Resto = 0;
            }
            if (Resto !== parseInt(strCPF.substring(10, 11))) {
                errors.cpf = 'CPF invalido'
            } else {
            }

        }
    }


    return errors
}

export default validate;