import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

const {Types, Creators} = createActions({
    setIsDependent:['value'],

});

export const FormPatientTypes = Types;

export const INITIAL_STATE = Immutable({
    isDependent:false,
});

export const reducer = createReducer(INITIAL_STATE,{
    [Types.SET_IS_DEPENDENT]: (state, action) => state.merge({isDependent:action.value}),
});

export default Creators;