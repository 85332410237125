import React,  { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import api from '../../services/api';
import { useDispatch } from 'react-redux';
import FormCandidatoActions from '../../store/ducks/FormCandidato';

const FormTabs = () => {
    const history = useHistory();
    const formCandidatoData = useSelector(state => state.formCandidato.data);
    const candidatoFormName = useSelector(state => state.formCandidato.formName);  
        
    const [conjugeRoute, setConjugeRoute] = useState('');
    const [enderecoRoute, setEnderecoRoute] = useState('');
    const [demograficoRoute, setDemograficoRoute] = useState('');
    const [familiaRoute, setFamiliaRoute] = useState('');
    const [documentosRoute, setDocumentosRoute] = useState('');
    const dispatch = useDispatch();  
    
    const getInfoCandidato = () =>  {
        const urlTokens = history.location.pathname.split("/");
        
        api.get(`candidatos/${urlTokens[2]}`).then(response => {            
            
            dispatch(FormCandidatoActions.setData(response.data))

            setConjugeRoute(response.data.conjugeId ? `/candidatos/${response.data.id}/conjuge/${response.data.conjugeId}` : `/candidatos/${response.data.id}/conjuge/new`);
            setEnderecoRoute(response.data.enderecoId ? `/candidatos/${response.data.id}/endereco/${response.data.enderecoId}` : `/candidatos/${response.data.id}/endereco/new`);
            setDemograficoRoute(response.data.demograficoId ? `/candidatos/${response.data.id}/demografico/${response.data.demograficoId}` : `/candidatos/${response.data.id}/demografico/new`);
            setFamiliaRoute(response.data.id && `/candidatos/${response.data.id}/familia/${response.data.id}`);
            setDocumentosRoute(`/candidatos/${response.data.id}/documentos`);

        });
    }

    const configRoutesFunc = () => {  
        if (formCandidatoData.cadastropessoal) {
            setUrls();
        } 
        if (!formCandidatoData.id ) {
            getInfoCandidato();
        }

    }

    const setUrls = () =>{
        
        setConjugeRoute(formCandidatoData.conjugeId ? `/candidatos/${formCandidatoData.id}/conjuge/${formCandidatoData.conjugeId}` : `/candidatos/${formCandidatoData.id}/conjuge/new`);
        setEnderecoRoute(formCandidatoData.enderecoId ? `/candidatos/${formCandidatoData.id}/endereco/${formCandidatoData.enderecoId}` : `/candidatos/${formCandidatoData.id}/endereco/new`);
        setDemograficoRoute(formCandidatoData.demograficoId ? `/candidatos/${formCandidatoData.id}/demografico/${formCandidatoData.demograficoId}` : `/candidatos/${formCandidatoData.id}/demografico/new`);
        setFamiliaRoute(formCandidatoData.id && `/candidatos/${formCandidatoData.id}/familia/${formCandidatoData.id}`);
        setDocumentosRoute(`/candidatos/${formCandidatoData.id}/documentos`);
    }

    useEffect(() => {

	 configRoutesFunc();
            
         
    }, []);

    function candidatoHandler(){
        if (formCandidatoData){
            history.push(`/candidatos/${formCandidatoData.id}`);
        }
        
    }

    return (
        <>
        
            {
                  
                formCandidatoData.cadastropessoal &&
                <>
                    <Button type="button"  color={(candidatoFormName.formName==="Candidato")?"primary":"inherit"} variant="contained" onClick={() => candidatoHandler()} > Candidato</Button>
                       
                    
                    {                        
                        (formCandidatoData.cadastropessoal.estadocivil === 4 || 
                         formCandidatoData.cadastropessoal.estadocivil === 6) && <Button type="button"  color={(candidatoFormName.formName==="Conjuge")?"primary":"inherit"} variant="contained" onClick={() => history.push(conjugeRoute)}>Conjuge</Button>
                    }
                    <Button type="button"  color={(candidatoFormName.formName==="Endereco")?"primary":"inherit"} variant="contained" onClick={() => history.push(enderecoRoute)}>Endereço</Button>
                    {
                        formCandidatoData.id && <Button type="button" color={(candidatoFormName.formName==="Família")?"primary":"inherit"} variant="contained" onClick={() => history.push(familiaRoute)}>Família</Button>
                    }
                    <Button type="button"  color={(candidatoFormName.formName==="Demográfico")?"primary":"inherit"} variant="contained" onClick={() => history.push(demograficoRoute)}>Demográfico</Button>
                    <Button type="button"  color={(candidatoFormName.formName==="Documentos")?"primary":"inherit"} variant="contained" onClick={() => history.push(documentosRoute)}>Documentos</Button>
                </>
                
            }
        </>
    )
}

export default FormTabs;
