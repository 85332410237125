import validCpf from '../../services/validCpf';

const validate = values => {
    const errors = {}
    var requiredFields = [
        'cpf',
        'password',
        'companyId'
    ]

    // VALIDAÇAO CAMPOS REQUIRE
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Campo obrigatório'
        }
    })

    if (values.cpf && !validCpf(values.cpf)) {
        errors.cpf = 'CPF invalido';
    }

    return errors
}

export default validate;