import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { renderTextField, renderTextAreaField } from '../../../components/Fields/Fields';
import BasicForm from '../../../components/BasicForm';
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment';
import { renderSelectField } from '../../../components/Fields/Fields';

const formName = 'Concorrencia';
const modelName = 'concorrencias';
const label = 'Concorrência';

const validate = (values, props) => {
    const errors = {}

    const requiredFields = [        
        'name',
        'startDate',
        'endDate',
    ]

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Campo Origatório'
        }
    })

    return errors;
}

let Form = (props) => {
    
    const upper = value => value && value.toUpperCase()
    
    return (
        <BasicForm name={'Concorrencias'}
            content={
                <Grid container spacing={3} wrap="wrap">

                    <Grid item xs={6}>
                        <Field name="name" type="text" component={renderTextField} label="Nome" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>

                    <Grid item xs={6}>
                        <Field name="description" type="text" component={renderTextField} label="Descrição" normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>

                    <Grid item xs={6}>
                        <Field name="number" type="text" component={renderTextField} label="Número de Ref." normalize={upper} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>

                    <Grid item xs={6}>
                        <Field id="status" name="status" component={renderSelectField} label="Status" style={{ maxWidth: '100%', width: 300 }}>
                            <option value="" />
                            <option value={'A'}>ATIVO</option>
                            <option value={'I'}>INATIVO</option>
                            <option value={'P'}>PENDENTE</option>
                        </Field>
                    </Grid>                    

                    <Grid item xs={6}>
                        <InputLabel>Data Inicio</InputLabel>
                        <Field name="startDate" type="date" component={renderTextField} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>

                    <Grid item xs={6}>
                        <InputLabel>Data Encerramento</InputLabel>
                        <Field name="endDate" type="date" component={renderTextField} style={{ maxWidth: '100%', width: 300 }} />
                    </Grid>      

                    <Grid item xs={12}>
                        <Field name="editalLink" type="text" component={renderTextField} label="Link Edital" normalize={upper} style={{ maxWidth: '100%', width: 750 }} />
                    </Grid>      

                    <Grid item xs={12}>
                        <Field name="editalText" type="memo" component={renderTextAreaField} label="Texto do Edital" normalize={upper} style={{ maxWidth: '100%', width: 750 }} />
                    </Grid>

                </Grid>
            }
            formName={formName}
            modelName={modelName}
            label={label}
            { ...props }
        />
    );
};

Form = reduxForm({
    form: formName,
    validate,
    initialValues: {
        'status':'A',
        'birthDate': moment().format('YYYY-MM-DD'),
        'valpassword':  '000000000',
        'confpassword': '000000000',
        
    }
})(Form);

export default Form;

