import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { reducer as formPatient } from './FormPatient';
import { reducer as gridList } from './GridList';
import { reducer as formCandidato } from './FormCandidato';
import { reducer as carousel } from './Carousel';
import { reducer as formRegistroIndividual } from './FormRegistroIndividual';


const reducers = combineReducers({
    form: reduxFormReducer,
    formPatient,
    gridList,
    formCandidato,
    carousel,
    formRegistroIndividual,
});

export default reducers;
